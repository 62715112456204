import dudocLogo from '@/assets/svg/dudoc-logo-full.svg';
import dudocLogoIcon from '@/assets/svg/dudoc-logo-icon.svg';
import r7Logo from '@/assets/svg/r7-logo-full.svg';
import r7LogoIcon from '@/assets/svg/r7-logo-icon.svg';
import { CUSTOMER } from '@/config/constants';
import { useIsDarkTheme } from '@/helpers/themeUtils';
import { Image, ImageProps } from '@mantine/core';

export const Logotype = ({
  minified,
  ...props
}: ImageProps & { minified?: boolean }) => {
  const isDark = useIsDarkTheme();

  const getLogoSrc = () => {
    if (isDark) {
      switch (CUSTOMER) {
        case 'R7':
          return minified ? r7Logo : r7Logo;
        default:
          return minified ? dudocLogoIcon : dudocLogo;
      }
    }
    switch (CUSTOMER) {
      case 'R7':
        return minified ? r7LogoIcon : r7Logo;
      default:
        return minified ? dudocLogoIcon : dudocLogo;
    }
  };

  return <Image src={getLogoSrc()} {...props} />;
};

export const getLogoSize = (minified?: boolean) => {
  switch (CUSTOMER) {
    case 'R7':
      return minified ? { h: 40, w: 180 } : { h: 60, w: 320 };
    default:
      return { h: 30, w: 160 };
  }
};
