import { Link } from 'react-router-dom';

import { SHOW_FORMS } from '@/config/constants';
import { AppRoutes } from '@/config/routes';
import { useActiveLink } from '@/hooks/useActiveLink';
import { Box, Button } from '@mantine/core';
import { IconForms, IconTrash } from '@tabler/icons-react';

import { useMenu } from '../../hooks/useMenu';

export const Footer = () => {
  const { isBurger, close } = useMenu();

  const isFormsActive = useActiveLink(AppRoutes.formsList);
  const isTrashActive = useActiveLink(AppRoutes.trash);

  return (
    <>
      <Box p={16}>
        {SHOW_FORMS && (
          <Link
            to={AppRoutes.formsList}
            onClick={() => {
              isBurger && close();
            }}
          >
            <Button
              leftSection={<IconForms size={16} />}
              variant={isFormsActive ? 'light' : 'subtle'}
              color="dark"
              size="sm"
              fullWidth
              justify="flex-start"
            >
              Формы
            </Button>
          </Link>
        )}
        <Link
          to={AppRoutes.trash}
          onClick={() => {
            isBurger && close();
          }}
        >
          <Button
            leftSection={<IconTrash size={16} />}
            variant={isTrashActive ? 'light' : 'subtle'}
            size="sm"
            fullWidth
            justify="flex-start"
            color="dark"
          >
            Корзина
          </Button>
        </Link>
      </Box>
    </>
  );
};
