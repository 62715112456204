import { Dispatch, SetStateAction, useCallback } from 'react';

import { BasePageData } from '@/features/CreatePageModal/CreatePageModal';
import { ActionIcon, Menu } from '@mantine/core';
import { IconDots } from '@tabler/icons-react';

import { PageActionsContent } from './PageActionsContent';

export const PageActions = <
  PAGE extends Pick<
    BasePageData,
    'id' | 'title' | 'icon' | 'parentId' | 'workspaceId'
  >,
>({
  page,
  opened,
  setOpened,
}: {
  page: PAGE;
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
}) => {
  const handleIconClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();

      setOpened((o) => !o);
    },
    [setOpened],
  );

  return (
    <Menu opened={opened} onChange={setOpened} withinPortal>
      <Menu.Target>
        <ActionIcon variant="subtle" color="dark.1" onClick={handleIconClick}>
          <IconDots size={18} color="var(--mantine-color-gray-5)" />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown fw={400} w={300} onClick={(e) => e.stopPropagation()}>
        <PageActionsContent opened={opened} page={page} setOpened={setOpened} />
      </Menu.Dropdown>
    </Menu>
  );
};
