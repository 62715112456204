import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

import { getAccessToken } from './helpers/token';

const APP_API = import.meta.env.VITE_APP_API || '';
const BACKEND_TYPE = import.meta.env.VITE_BACKEND_TYPE || '';

const DEFAULT_BASE_URL = BACKEND_TYPE === 'R7' ? '/app' : APP_API;

export const customBaseQuery = (baseUrl = DEFAULT_BASE_URL) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: async (headers) => {
      const accessToken = getAccessToken();
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      return headers;
    },
  });

  const isAuthRequest = (args: string | FetchArgs) => {
    const notRedirect = [
      '/v1/register',
      '/v1/login',
      '/v1/users',
      '/v1/invitation',
      '/v1/public/pages',
      '/app/oauth2',
    ];

    return (
      typeof args !== 'string' &&
      'url' in args &&
      notRedirect.some((url) => url.startsWith(args.url.toLowerCase()))
    );
  };

  const baseQueryWithReAuth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
  > = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);

    if (!isAuthRequest(args) && result.error && result.error.status === 401) {
      window.location.replace('/login');
    }

    return result;
  };

  return baseQueryWithReAuth;
};
