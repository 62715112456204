import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Center,
  Group,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { IconChevronLeft, IconHome2 } from '@tabler/icons-react';

interface NotFoundPageProps {
  isPublicForm?: boolean;
}

export const NotFoundPage = ({ isPublicForm }: NotFoundPageProps) => {
  const { t } = useTranslation();

  const theme = useMantineTheme();
  const navigate = useNavigate();

  return (
    <Center
      style={{
        padding: theme.spacing.md,
        height: '100dvh',
        color: theme.colors.dark[8],
      }}
    >
      <Stack>
        <div>404</div>
        <Title>{t('404.title')}</Title>
        <Text fz="md" ta="center">
          {isPublicForm ? t('404.publicDescription') : t('404.description')}
        </Text>
        <Group justify="center" mt="md">
          <Button
            size="md"
            variant="subtle"
            leftSection={<IconChevronLeft size={18} />}
            onClick={() => navigate(-1)}
          >
            {t('404.goBack')}
          </Button>
          <Button
            size="md"
            variant="subtle"
            leftSection={<IconHome2 size={18} />}
            onClick={() => navigate('/')}
          >
            {t('404.goHome')}
          </Button>
        </Group>
      </Stack>
    </Center>
  );
};
