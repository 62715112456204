import { useTranslation } from 'react-i18next';

import { showRequestNotifications } from '@/api/helpers/showNotifications';
import { workspacesApi } from '@/api/workspaces';
import { useMenu } from '@/components/layout/hooks/useMenu';
import { removePage } from '@/hooks/useRemove';
import { Alert, Button, Flex, Group } from '@mantine/core';
import { IconCornerUpLeft, IconX } from '@tabler/icons-react';
import { addDays, format } from 'date-fns';

import { BasePageData } from '../CreatePageModal/CreatePageModal';

export const RemovedPageBanner = <
  PAGE extends Pick<BasePageData, 'id' | 'deleted' | 'deletedAt' | 'title'>,
>({
  page,
}: {
  page: PAGE;
}) => {
  const [restore] = workspacesApi.endpoints.restorePage.useMutation();
  const { t } = useTranslation();
  const { isMobile } = useMenu();

  if (!page.deleted) return null;

  const handleRestore = (id: string) => {
    try {
      const result = restore(id);
      showRequestNotifications(result, {
        successMsg: t('trash.pageRestored', { pageName: page.title }),
        errorMsg: t('trash.pageRestoreFailed', { pageName: page.title }),
      });
    } catch (error) {
      showRequestNotifications(Promise.reject(error), {
        notifyId: 'restore-file',
        errorMsg: t('trash.pageRestoreFailed', { pageName: page.title }),
      });
      console.error('Failed to update user:', error);
    }
  };

  const deletedAt = format(new Date(page.deletedAt || ''), 'dd MMM');
  const permanentDeletedAt = format(
    addDays(new Date(page.deletedAt || ''), 30),
    'dd MMM',
  );

  return (
    <Alert radius={0} color="red">
      <Flex justify={'center'} direction={isMobile ? 'column' : 'row'}>
        Документ удален {deletedAt} и будет окончательно удален{' '}
        {permanentDeletedAt}
        <Group gap={5}>
          <Button
            size="compact-sm"
            leftSection={<IconCornerUpLeft size={16} />}
            variant="outline"
            onClick={() => handleRestore(page.id)}
          >
            {t('trash.restore')}
          </Button>
          <Button
            size="compact-sm"
            leftSection={<IconX size={16} />}
            variant="outline"
            onClick={() =>
              removePage(page.title, page.id, {
                permanent: true,
                redirect: true,
              })
            }
          >
            Удалить навсегда
          </Button>
        </Group>
      </Flex>
    </Alert>
  );
};
