import { PropsWithChildren, ReactNode, useEffect } from 'react';

import { useAppSelector } from '@/app/store';
import { useMenu } from '@/components/layout/hooks/useMenu';
import { AppMenu } from '@/components/ui/AppMenu';
import { UserMenu } from '@/components/ui/UserMenu';
import { HEADER_HEIGHT } from '@/config/constants';
import { useSwipeDetect } from '@/hooks/useSwipeDetect';
import {
  ActionIcon,
  AppShell,
  Box,
  Burger,
  Drawer,
  Flex,
  ScrollArea,
} from '@mantine/core';
import { IconChevronsLeft, IconChevronsRight } from '@tabler/icons-react';

import { NavbarResizer } from '../Sidebar/components/NavbarResizer/NavbarResizer';

import styles from './AppLayout.module.css';

export const AppLayout = ({
  content,
  header,
  navbar,
  hideUserMenu,
}: {
  content?: ReactNode;
  header?: ReactNode;
  navbar?: ReactNode;
  hideUserMenu?: boolean;
}) => {
  const sidebarWidth = useAppSelector((s) => s.layoutSlice.sidebarWidth);
  const showHeader = useAppSelector((s) => s.layoutSlice.showHeader);

  const { opened, isMobile } = useMenu();

  return (
    <AppShell
      withBorder={false}
      layout="alt"
      header={{ height: HEADER_HEIGHT, collapsed: !showHeader }}
      navbar={{
        width: sidebarWidth,
        breakpoint: 'sm',
        collapsed: {
          mobile: isMobile ? !opened : undefined,
          desktop: !isMobile ? !opened : undefined,
        },
      }}
    >
      {header && <Header hideUserMenu={hideUserMenu}>{header}</Header>}

      {navbar && <Navbar>{navbar}</Navbar>}

      {content && (
        <AppShell.Main
          style={{
            display: 'flex',
          }}
        >
          <Box flex={1} w={'100%'} pos={'relative'}>
            {content}
          </Box>
        </AppShell.Main>
      )}
    </AppShell>
  );
};

const Navbar = ({ children }: PropsWithChildren) => {
  const { initSidebar, isMobile, opened, toggle } = useMenu();

  const sidebarWidth = useAppSelector((s) => s.layoutSlice.sidebarWidth);

  const swipeProps = useSwipeDetect((side) => {
    if (side === 'left') toggle();
  });

  useEffect(() => {
    initSidebar();
  }, [initSidebar]);

  return isMobile ? (
    <Drawer
      {...swipeProps}
      styles={{
        body: {
          padding: 0,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
      withCloseButton={false}
      overlayProps={{ opacity: 0.5 }}
      opened={opened}
      onClose={toggle}
      size={sidebarWidth}
    >
      {children}
    </Drawer>
  ) : (
    <AppShell.Navbar
      style={{ zIndex: 100 }}
      classNames={{ navbar: styles.navbar }}
    >
      {children}
      {opened && <NavbarResizer />}
    </AppShell.Navbar>
  );
};

const Header = ({
  children,
  hideUserMenu,
}: PropsWithChildren<{ hideUserMenu?: boolean }>) => {
  const { opened, toggle } = useMenu();

  return (
    <AppShell.Header>
      <Flex h={'100%'} justify="space-between" px={16} gap={8} pl={8}>
        <Flex flex={1}>
          {!opened && (
            <ActionIcon
              variant="subtle"
              color="dark.1"
              visibleFrom="lg"
              onClick={toggle}
            >
              <IconChevronsRight size={16} />
            </ActionIcon>
          )}
          <Burger
            opened={opened}
            onClick={toggle}
            hidden={opened}
            hiddenFrom="lg"
            size="sm"
            color="gray.6"
          />
          {children}
        </Flex>

        {!hideUserMenu && (
          <Flex>
            <AppMenu />
            <UserMenu />
          </Flex>
        )}
      </Flex>
    </AppShell.Header>
  );
};

export const NavbarHeader = ({
  children,
  showDivider,
}: PropsWithChildren<{
  showDivider: boolean;
}>) => {
  const { toggle, isMobile } = useMenu();

  return (
    <AppShell.Section>
      <Flex
        justify={'space-between'}
        align={'center'}
        px={16}
        h={HEADER_HEIGHT}
        className={styles.headerContainer}
        data-show-divider={showDivider}
      >
        {children}

        <ActionIcon
          variant={isMobile ? 'transparent' : 'subtle'}
          color="dark.1"
          onClick={toggle}
        >
          <IconChevronsLeft size={16} />
        </ActionIcon>
      </Flex>
    </AppShell.Section>
  );
};

export const NavbarScrollableContent = ({
  children,
  onScroll,
}: PropsWithChildren<{
  onScroll: (position: { x: number; y: number }) => void;
}>) => {
  return (
    <AppShell.Section
      grow
      scrollbarSize={6}
      component={ScrollArea}
      scrollbars={'y'}
      onScrollPositionChange={onScroll}
      flex={1}
    >
      <div
        style={{
          width: 'var(--app-shell-navbar-width)',
        }}
      >
        {children}
      </div>
    </AppShell.Section>
  );
};
