import { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useCopyPageLink } from '@/hooks/useCopyPageLink';
import { useFavorite } from '@/hooks/useFavorite';
import { useOpenPageNewTab } from '@/hooks/useOpenNewTab';
import { usePageDuplicate } from '@/hooks/usePageDuplicate';
import { usePageExportPDF } from '@/hooks/usePageExportPDF';
import { usePageState } from '@/hooks/usePageState';
import { removePage } from '@/hooks/useRemove';
import { useAccess } from '@/hooks/useWsProvider';
import {
  ActionIcon,
  Box,
  Flex,
  Menu,
  MenuItemProps,
  PolymorphicComponentProps,
  Switch,
  Text,
} from '@mantine/core';
import {
  IconArrowUpRight,
  IconCopy,
  IconDots,
  IconFileTypePdf,
  IconLink,
  IconLock,
  IconLockOpen,
  IconStar,
  IconStarFilled,
  IconTrash,
} from '@tabler/icons-react';
import { produce } from 'immer';
import { isNil } from 'lodash-es';

import { BasePageData } from '../CreatePageModal/CreatePageModal';

import styles from './PageOptions.module.css';

const fontStyles = ['system', 'serif', 'mono'];

export const PageOptions = <
  PAGE extends Pick<
    BasePageData,
    'id' | 'pageSettings' | 'title' | 'workspaceId' | 'deleted'
  >,
>({
  page,
  handleSettings,
}: {
  page: PAGE;
  handleSettings?: (s: BasePageData['pageSettings']) => void;
}) => {
  const { t } = useTranslation();
  const access = useAccess();
  const isCreatePage = page.id === 'new_page';

  const { /*smallText,*/ fullWidth, isLock, canEdit } = usePageState(page);

  const handleOpenNewTab = useOpenPageNewTab(page.id);
  const handleDuplicate = usePageDuplicate(page.id);
  const handleCopyLink = useCopyPageLink(page.id);
  const handleRemove = useCallback(
    () => removePage(page.title, page.id, { redirect: true }),
    [page.id, page.title],
  );
  const handleExport = usePageExportPDF(page);

  const { addFavorite, deleteFavorite, isFavorite } = useFavorite(page.id);

  const items = useMemo<
    Array<
      PolymorphicComponentProps<'button', MenuItemProps> & {
        available?: boolean;
        hasDivider?: boolean;
      }
    >
  >(() => {
    return [
      //   {
      //     rightSection: (
      //       <Switch
      //         checked={smallText}
      //         onChange={() => {
      //           handleSettings?.(
      //             produce(page.pageSettings, (draft) => {
      //               draft.smallText = !draft.smallText;
      //             }),
      //           );
      //         }}
      //       />
      //     ),
      //     children: t('pageOptions.smallText'),
      //     available: canEdit,
      //   },

      {
        rightSection: (
          <Switch
            checked={fullWidth}
            onChange={() => {
              handleSettings?.(
                produce(page.pageSettings, (draft) => {
                  draft.fullWidth = !draft.fullWidth;
                }),
              );
            }}
          />
        ),
        children: t('pageOptions.allWidth'),
        available: canEdit,
      },

      {
        leftSection: <IconLockOpen size={18} />,
        children: t('pageOptions.unlockPage'),
        available:
          isLock && access.pages.edit && !page.deleted && !isCreatePage,
        onClick: () => {
          handleSettings?.(
            produce(page.pageSettings, (draft) => {
              draft.lock = !draft.lock;
            }),
          );
        },
      },

      {
        leftSection: <IconLock size={18} />,
        children: t('pageOptions.lockPage'),
        available:
          !isLock && access.pages.edit && !page.deleted && !isCreatePage,
        onClick: () => {
          handleSettings?.(
            produce(page.pageSettings, (draft) => {
              draft.lock = !draft.lock;
            }),
          );
        },
      },

      {
        leftSection: (
          <IconStarFilled
            size={18}
            color="light-dark(var(--mantine-color-yellow-6), var(--mantine-color-yellow-1))"
          />
        ),
        children: t('removeFavorite'),
        available: isFavorite && !isCreatePage,
        onClick: deleteFavorite,
      },

      {
        leftSection: <IconStar size={18} />,
        children: t('favorite'),
        available: !isFavorite && !isCreatePage,
        onClick: addFavorite,
      },

      {
        closeMenuOnClick: true,
        leftSection: <IconLink size={18} />,
        children: t('copyLink'),
        available: !isCreatePage,
        onClick: handleCopyLink,
      },

      {
        closeMenuOnClick: true,
        leftSection: <IconCopy size={18} />,
        children: t('duplicate'),
        available: access.pages.create && !isCreatePage,
        onClick: handleDuplicate,
      },

      {
        closeMenuOnClick: true,
        leftSection: <IconArrowUpRight size={18} />,
        children: t('openInNewTab'),
        available: !isCreatePage,
        onClick: handleOpenNewTab,
      },

      {
        closeMenuOnClick: true,
        leftSection: <IconFileTypePdf size={18} />,
        onClick: handleExport,
        available: !isCreatePage,
        children: t('pageOptions.exportPdf'),
      },

      {
        closeMenuOnClick: true,
        leftSection: <IconTrash size={18} />,
        color: 'red',
        children: 'Удалить',
        available: access.pages.delete && !isCreatePage,
        onClick: handleRemove,
      },
    ].filter((el) => el.available || isNil(el.available));
  }, [
    access.pages,
    addFavorite,
    canEdit,
    deleteFavorite,
    handleCopyLink,
    handleDuplicate,
    handleExport,
    handleOpenNewTab,
    handleRemove,
    handleSettings,
    isFavorite,
    fullWidth,
    isCreatePage,
    isLock,
    page.deleted,
    page.pageSettings,
    t,
  ]);

  return (
    <Menu
      shadow="md"
      position="bottom-end"
      width={350}
      closeOnItemClick={false}
    >
      <Menu.Target>
        <ActionIcon variant="subtle" size="lg" color="gray">
          <IconDots size={20} />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        {canEdit && (
          <>
            <Menu.Label>{t('pageOptions.style')}</Menu.Label>
            <Flex align={'stretch'}>
              {fontStyles.map((fontStyle) => (
                <Box
                  key={fontStyle}
                  className={styles.fontTile}
                  data-style={fontStyle}
                  data-active={page.pageSettings.font === fontStyle}
                  p={8}
                  flex={1}
                  onClick={
                    canEdit
                      ? () =>
                          handleSettings?.(
                            produce(page.pageSettings, (draft) => {
                              draft.font = fontStyle;
                            }),
                          )
                      : undefined
                  }
                >
                  <div className={styles.title}>Ag</div>
                  <Text size="xs" c="dimmed">
                    {t('pageOptions.fontStyles.' + fontStyle)}
                  </Text>
                </Box>
              ))}
            </Flex>
            <Menu.Divider />
          </>
        )}

        {items.map(({ hasDivider, available: _, ...el }, index) => (
          <Fragment key={index}>
            {hasDivider && <Menu.Divider />}
            <Menu.Item {...el} />
          </Fragment>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
