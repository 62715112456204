import { favoritesApi } from '@/api/favorites/slice';

export const useFavorite = (pageId: string) => {
  const { data = [] } = favoritesApi.endpoints.getFavorites.useQueryState();
  const [addFavorite] = favoritesApi.endpoints.addFavorite.useMutation();
  const [deleteFavorite] = favoritesApi.endpoints.deleteFavorite.useMutation();

  return {
    isFavorite: data?.some((el) => el.id === pageId),
    addFavorite: () => addFavorite(pageId),
    deleteFavorite: () => deleteFavorite(pageId),
  };
};
