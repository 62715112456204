import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import { z } from 'zod';
import { zodI18nMap } from 'zod-i18n-map';
import enZod from 'zod-i18n-map/locales/en/zod.json';
import ruZod from 'zod-i18n-map/locales/ru/zod.json';

import en from './locales/en.json';
import ru from './locales/ru.json';

const resources = {
  en: {
    translation: en,
    zod: enZod,
  },
  ru: {
    translation: ru,
    zod: ruZod,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'ru',
  interpolation: {
    escapeValue: false,
  },
});
z.setErrorMap(zodI18nMap);

export default i18n;
