import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { authApi } from '@/api/auth';
import { APP_NAME } from '@/config/constants';
import { AppRoutes } from '@/config/routes';
import {
  Alert,
  Button,
  FocusTrap,
  LoadingOverlay,
  Paper,
  PasswordInput,
  Stack,
  Text,
  TextInput,
  Title,
  rem,
  useComputedColorScheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { IconAt, IconBrandSamsungpass, IconUser } from '@tabler/icons-react';

type FormValues = {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
};

export const RegisterForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const colorScheme = useComputedColorScheme();

  const iconAt = <IconAt style={{ width: rem(16), height: rem(16) }} />;
  const iconUser = <IconUser style={{ width: rem(16), height: rem(16) }} />;
  const iconPass = (
    <IconBrandSamsungpass style={{ width: rem(16), height: rem(16) }} />
  );

  const [register, { isLoading, isError, isSuccess }] =
    authApi.endpoints.register.useMutation();
  const [visiblePassword, { toggle: togglePassword }] = useDisclosure(false);
  const [visibleConfirmPassword, { toggle: toggleConfirmPassword }] =
    useDisclosure(false);

  const form = useForm<FormValues>({
    initialValues: {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    validate: {
      name: (v) => {
        const trimmedValue = (v || '').trim();
        if (trimmedValue.length === 0) {
          return t('errors.EMPTY_FIELD');
        }
        if (trimmedValue.length > 20) {
          return t('errors.MAX_LENGTH_EXCEEDED', { max: 20 });
        }
        return null;
      },
      email: (value) => {
        const trimmedValue = (value || '').trim();
        if (trimmedValue.length === 0) {
          return t('errors.EMPTY_FIELD');
        }
        if (trimmedValue.length > 70) {
          return t('errors.MAX_LENGTH_EXCEEDED', { max: 70 });
        }
        return /^\S+@\S+$/.test(value)
          ? null
          : 'Введите действительный адрес электронной почты!';
      },
      password: (v) => {
        const trimmedValue = (v || '').trim();
        if (trimmedValue.length === 0) {
          return t('errors.EMPTY_FIELD');
        }
        if (trimmedValue.length > 50) {
          return t('errors.MAX_LENGTH_EXCEEDED', { max: 50 });
        }
        return null;
      },
      confirmPassword: (v, values) => {
        return v === values.password ? null : 'Пароли должны совпадать';
      },
    },
  });

  const getToLoginUrl = () => {
    let url = AppRoutes.login;
    const returnUrl = location.search;
    if (returnUrl.includes('invitation')) {
      url += returnUrl;
    }
    return url;
  };

  const handleSubmit = (values: typeof form.values) => {
    register({
      name: values.name,
      email: values.email,
      password: values.password,
      isDarkMode: colorScheme === 'dark',
      profilePicture: {
        url: '',
      },
    })
      .unwrap()
      .then(() => {
        const queryParams = new URLSearchParams(location.search);
        const returnUrl = queryParams.get('returnUrl') || '/';
        if (returnUrl) {
          navigate(returnUrl, { replace: true });
        } else {
          navigate('/', { replace: true });
        }
      });
  };

  return (
    <Paper p={24} w={'100%'} maw={450}>
      <form onSubmit={form.onSubmit(handleSubmit)} data-cy="loginForm">
        <LoadingOverlay
          visible={isLoading}
          zIndex={1000}
          overlayProps={{ radius: 'sm', blur: 2 }}
        />
        <FocusTrap active>
          <Stack gap={16}>
            <Title order={2}>Регистрация</Title>
            <Text fz={'sm'}>Создайте учётную запись в {APP_NAME || ''}</Text>

            {isError && <Alert color={'red'}>Ошибка регистрации</Alert>}
            {isSuccess && <Alert color={'blue'}>Успешная регистрация!</Alert>}

            <TextInput
              data-autofocus
              placeholder="Введите ваше имя"
              size="md"
              leftSectionPointerEvents="none"
              leftSection={iconUser}
              required
              {...form.getInputProps('name')}
            />

            <TextInput
              placeholder="Введите ваш e-mail"
              size="md"
              leftSectionPointerEvents="none"
              leftSection={iconAt}
              required
              {...form.getInputProps('email')}
            />

            <PasswordInput
              leftSection={iconPass}
              size="md"
              placeholder="Введите ваш пароль"
              required
              visible={visiblePassword}
              onVisibilityChange={togglePassword}
              {...form.getInputProps('password')}
            />

            <PasswordInput
              leftSection={iconPass}
              size="md"
              placeholder="Повторите ваш пароль"
              required
              visible={visibleConfirmPassword}
              onVisibilityChange={toggleConfirmPassword}
              {...form.getInputProps('confirmPassword')}
            />

            <Button
              type="submit"
              fullWidth
              data-cy="submitButton"
              variant="filled"
              size="md"
            >
              Зарегистрироваться
            </Button>

            <Text size="sm">
              Уже есть аккаунт ? <NavLink to={getToLoginUrl()}>Вход</NavLink>
            </Text>
          </Stack>
        </FocusTrap>
      </form>
    </Paper>
  );
};
