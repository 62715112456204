import { customBaseQuery } from '@/api/customBaseQuery';
import { BasePageData } from '@/features/CreatePageModal/CreatePageModal';
import { deepFind } from '@/helpers/deepFind';
import { createApi } from '@reduxjs/toolkit/query/react';
import { isUndefined } from 'lodash-es';
import queryString from 'query-string';

import { favoritesApi } from '../favorites/slice';
import { usersApi } from '../users';
import { ApiPages, ApiWorkspaces } from './types';

export const workspacesApi = createApi({
  reducerPath: 'workspacesApi',
  baseQuery: customBaseQuery(),
  tagTypes: ['Page', 'Hierarchy', 'Members', 'Trash'],
  endpoints: (builder) => ({
    createWorkspace: builder.mutation<
      ApiWorkspaces.ICreateResponse,
      ApiWorkspaces.ICreateRequest
    >({
      query: (body) => ({
        url: `/v1/workspaces`,
        method: 'POST',
        body,
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          dispatch(usersApi.util.invalidateTags(['CurrentUser']));
        } catch (err) {
          console.error(err);
        }
      },
    }),
    updateWorkspace: builder.mutation<
      ApiWorkspaces.IWorkspace,
      ApiWorkspaces.IUpdateWorkspace
    >({
      query: (data) => ({
        url: `/v1/workspaces/${data.workspaceId}`,
        method: 'PATCH',
        body: {
          name: data.name,
          avatarUrl: data.avatarUrl,
        },
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          dispatch(usersApi.util.invalidateTags(['CurrentUser']));
        } catch (err) {
          console.error(err);
        }
      },
    }),
    deleteWorkspace: builder.mutation<void, ApiWorkspaces.IDeleteRequest>({
      query: (data) => ({
        url: `/v1/workspaces/${data.workspaceId}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ workspaceId }, { queryFulfilled, dispatch }) {
        const patchResult = dispatch(
          usersApi.util.updateQueryData(
            'getCurrentUser',
            undefined,
            (draft) => {
              const index = draft.workspaces.findIndex(
                (w) => w.workspaceId === workspaceId,
              );
              if (index > -1) {
                draft.workspaces.splice(index, 1);
              }
            },
          ),
        );

        try {
          await queryFulfilled;
          dispatch(usersApi.util.invalidateTags(['CurrentUser']));
        } catch (err) {
          patchResult.undo();
        }
      },
    }),
    getHierarchy: builder.query<
      ApiPages.IHierarchyResponse,
      ApiPages.IHierarchyRequest
    >({
      query: ({ workspaceId, ...params }) => ({
        url: `/v1/workspaces/get/${workspaceId}/pages?${queryString.stringify(params)}`,
        method: 'GET',
      }),
      providesTags: (res) => (res ? [{ type: 'Hierarchy', id: 'LIST' }] : []),
    }),
    getPageById: builder.query<
      ApiPages.IGetByIdResponse,
      ApiPages.IGetByIdRequest
    >({
      query: (id) => ({
        url: `/v1/pages/${id}`,
        method: 'GET',
      }),
      providesTags: (res) => (res ? [{ type: 'Page', id: res.id }] : []),
    }),
    createPage: builder.mutation<
      ApiPages.ICreatePageResponse,
      ApiPages.ICreatePageRequest
    >({
      query: (body) => ({
        url: `/v1/pages`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, err) =>
        err ? [] : [{ type: 'Hierarchy', id: 'LIST' }],
    }),
    updatePage: builder.mutation<
      void,
      Partial<Omit<BasePageData, 'parentId'>> & { id: string }
    >({
      query: (data) => ({
        url: `/v1/pages/${data.id}`,
        method: 'PATCH',
        body: {
          title: data.title,
          icon: data.icon,
          coverPicture: data.coverPicture,
          content: data.content,
          pageSettings: data.pageSettings,
          workspaceId: data.workspaceId,
          iscrypted: data.iscrypted,
        },
      }),
      invalidatesTags: (_, err, args) =>
        args.content?.html || err ? [] : [{ type: 'Page', id: args.id }],

      onQueryStarted: async (args, { dispatch, queryFulfilled, getState }) => {
        const params = workspacesApi.util.selectCachedArgsForQuery(
          getState(),
          'getPageById',
        );

        const hierarchyParams = workspacesApi.util.selectCachedArgsForQuery(
          getState(),
          'getHierarchy',
        );

        const set = (v: any, prev: any) => (isUndefined(v) ? prev : v);

        const patchResults = params.map((el) => {
          return dispatch(
            workspacesApi.util.updateQueryData('getPageById', el, (draft) => {
              draft.title = set(args.title, draft.title);
              draft.icon = set(args.icon, draft.icon);
              draft.coverPicture = set(args.coverPicture, draft.coverPicture);
              draft.content = set(args.content, draft.content);
              draft.pageSettings = set(args.pageSettings, draft.pageSettings);
              draft.workspaceId = set(args.workspaceId, draft.workspaceId);
              draft.iscrypted = set(args.iscrypted, draft.iscrypted);
            }),
          );
        });

        const hierarchyPatchResults = hierarchyParams.map((el) => {
          return dispatch(
            workspacesApi.util.updateQueryData('getHierarchy', el, (draft) => {
              const page = deepFind(draft.pages, args.id);
              if (!page) return;

              page.title = set(args.title, page.title);
              page.icon = set(args.icon, page.icon);
            }),
          );
        });

        const patchFavoriteResults = dispatch(
          favoritesApi.util.updateQueryData(
            'getFavorites',
            undefined,
            (draft) => {
              const pageIndex = draft.findIndex((el) => el.id === args.id);

              if (pageIndex !== -1) {
                draft[pageIndex].title = set(
                  args.title,
                  draft[pageIndex].title,
                );
                draft[pageIndex].icon = set(args.icon, draft[pageIndex].icon);
              }
            },
          ),
        );

        try {
          await queryFulfilled;
          favoritesApi.util.invalidateTags([
            { type: 'Favorites', id: args.id },
          ]);
        } catch {
          patchResults.forEach((el) => el.undo());
          hierarchyPatchResults.forEach((el) => el.undo());
          patchFavoriteResults.undo();
        }
      },
    }),
    movePage: builder.mutation<
      void,
      {
        pageId: string;
        parentId: string | null;
        order: number;
      }
    >({
      query: (data) => ({
        url: `/v1/pages/${data.pageId}/move`,
        method: 'PATCH',
        body: {
          parentId: data.parentId,
          order: data.order,
        },
      }),
      invalidatesTags: (_, err, args) =>
        err
          ? []
          : [
              { type: 'Page', id: args.pageId },
              { type: 'Hierarchy', id: 'LIST' },
            ],
    }),
    deletePage: builder.mutation<void, ApiPages.IDeleteRequest>({
      query: ({ id, permanent }) => ({
        url: `/v1/pages/${id}?ispermanent=${permanent}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, err, { id }) =>
        err
          ? []
          : [
              { type: 'Page', id },
              { type: 'Hierarchy', id: 'LIST' },
              { type: 'Trash', id: 'LIST' },
            ],
      onQueryStarted: async ({ id }, { dispatch, queryFulfilled }) => {
        const patchResults = dispatch(
          workspacesApi.util.updateQueryData('getPageById', id, (draft) => {
            draft.deleted = true;
            draft.deletedAt = new Date().toISOString();
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResults.undo();
        }
      },
    }),
    searchPages: builder.query<
      ApiPages.IPageSearchResult,
      ApiPages.ISearchRequest
    >({
      query: (data) => ({
        url: `/v1/pages/search?${queryString.stringify(data)}`,
        method: 'GET',
      }),
    }),
    createInvitation: builder.mutation<
      ApiWorkspaces.IInvitationLinkResponse,
      ApiWorkspaces.IInvitationLinkRequest
    >({
      query: (data) => ({
        url: `/v1/invitations/${data.workspaceId}/invite`,
        method: 'POST',
        body: {
          email: data.email,
          role: data.role,
        },
      }),
      invalidatesTags: (_, err, args) =>
        err ? [] : [{ type: 'Members', id: args.workspaceId }],
    }),
    getInvitationInfo: builder.mutation<
      ApiWorkspaces.IGetWorkspaceInfoResponse,
      ApiWorkspaces.IGetWorkspaceInfoRequest
    >({
      query: (data) => ({
        url: `/v1/info/${data.inviteToken}`,
        method: 'GET',
      }),
    }),
    acceptInvitation: builder.mutation<
      ApiWorkspaces.IAcceptInvitationResponse,
      ApiWorkspaces.IAcceptInvitationRequest
    >({
      query: (data) => ({
        url: `/v1/invitations/accept/${data.inviteToken}`,
        method: 'POST',
        body: {},
      }),
    }),
    declineInvitation: builder.mutation<
      ApiWorkspaces.IDeclineInvitationResponse,
      ApiWorkspaces.IDeclineInvitationRequest & { workspaceId: string }
    >({
      query: (data) => ({
        url: `/v1/invitations/${data.inviteId}`,
        method: 'DELETE',
        body: {},
      }),
      invalidatesTags: (_, err, args) =>
        err ? [] : [{ type: 'Members', id: args.workspaceId }],
    }),
    assignRole: builder.mutation<
      ApiWorkspaces.IAssignRoleResponse,
      ApiWorkspaces.IAssignRoleRequest
    >({
      query: (data) => ({
        url: `/v1/workspaces/${data.workspaceId}/assign-role`,
        method: 'POST',
        body: {
          role: data.role,
          userId: data.userId,
        },
      }),
      invalidatesTags: (_, err, args) =>
        err ? [] : [{ type: 'Members', id: args.workspaceId }],
    }),
    removeMember: builder.mutation<
      ApiWorkspaces.IRemoveMemberResponse,
      ApiWorkspaces.IRemoveMemberRequest
    >({
      query: (data) => ({
        url: `/v1/workspaces/${data.workspaceId}/members/${data.userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, err, args) =>
        err ? [] : [{ type: 'Members', id: args.workspaceId }],
    }),
    getMembers: builder.query<
      ApiWorkspaces.WorkspaceMember[],
      ApiWorkspaces.IGetWorkspaceMembersRequest
    >({
      query: (data) => ({
        url: `/v1/workspaces/${data.workspaceId}/members`,
        method: 'GET',
      }),
      providesTags: (res, _, args) =>
        res ? [{ type: 'Members', id: args.workspaceId }] : [],
    }),
    exportToPDF: builder.mutation<Blob, string>({
      query: (pageId) => ({
        url: `/v1/pages/${pageId}/pdf`,
        method: 'GET',
        responseHandler: (response) => response.blob(),
      }),
    }),
    restorePage: builder.mutation<void, string>({
      query: (pageId) => ({
        url: `/v1/pages/${pageId}/restore`,
        method: 'PATCH',
      }),
      invalidatesTags: (_, err, pageId) =>
        err
          ? []
          : [
              { type: 'Page', id: pageId },
              { type: 'Trash', id: 'LIST' },
              { type: 'Hierarchy', id: 'LIST' },
            ],
    }),
    getTrash: builder.query<
      ApiPages.GetTrashResponse,
      ApiPages.GetTrashRequest
    >({
      query: ({ workspaceId, ...params }) => ({
        url: `/v1/workspaces/${workspaceId}/trash?${queryString.stringify(params)}`,
        method: 'GET',
      }),
      providesTags: (res) => (res ? [{ type: 'Trash', id: 'LIST' }] : []),
    }),
    getPublicPageById: builder.query<
      ApiPages.GetPublishedPageResponse,
      { pageId: string; pin?: string }
    >({
      query: ({ pageId, ...params }) => ({
        url: `/v1/public/pages/${pageId}?${queryString.stringify(params)}`,
        method: 'GET',
      }),
    }),
    publishPage: builder.mutation<
      void,
      ApiPages.PublishPageRequest & { childrenIds: string[] }
    >({
      query: ({ pageId, childrenIds: _, ...params }) => ({
        url: `/v1/pages/${pageId}/public?${queryString.stringify(params)}`,
        method: 'PATCH',
      }),
      invalidatesTags: (_, err, args) =>
        err
          ? []
          : [
              { type: 'Page', id: args.pageId },
              ...args.childrenIds.map((id) => ({ type: 'Page' as const, id })),
            ],
      onQueryStarted: async (
        { pageId, isPublic, includeChildren },
        { dispatch, queryFulfilled },
      ) => {
        const patchResults = dispatch(
          workspacesApi.util.updateQueryData('getPageById', pageId, (draft) => {
            draft.public = isPublic;
            draft.includeChildren = includeChildren;
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResults.undo();
        }
      },
    }),
    lockPage: builder.mutation<void, ApiPages.LockPageRequest>({
      query: ({ pageId, ...params }) => ({
        url: `/v1/pages/${pageId}/set-pin?${queryString.stringify(params)}`,
        method: 'POST',
      }),
      invalidatesTags: (_, err, args) =>
        err ? [] : [{ type: 'Page', id: args.pageId }],
      onQueryStarted: async ({ pageId }, { dispatch, queryFulfilled }) => {
        const patchResults = dispatch(
          workspacesApi.util.updateQueryData('getPageById', pageId, (draft) => {
            draft.iscrypted = true;
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResults.undo();
        }
      },
    }),
  }),
});
