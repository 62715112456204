import {
  ClipboardEventHandler,
  FocusEventHandler,
  FormEventHandler,
  KeyboardEventHandler,
  useEffect,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useAccess } from '@/hooks/useWsProvider';

import { BasePageData } from '../CreatePageModal/CreatePageModal';

import styles from './PageTitle.module.css';

export const PageTitle = <
  PAGE extends Pick<BasePageData, 'id' | 'title' | 'pageSettings'>,
>({
  page,
  handleTitle,
}: {
  page: PAGE;
  handleTitle?: (title: string) => void;
}) => {
  const { t } = useTranslation();
  const access = useAccess();
  const ref = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    ref.current!.textContent = page.title;
  }, [page.title]);

  const handleBlur: FocusEventHandler<HTMLHeadingElement> = (e) => {
    const el = e.currentTarget;
    const value = el.textContent?.trim() || '';

    if (value === page.title) return;
    handleTitle?.(value);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLHeadingElement> = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const handleInput: FormEventHandler<HTMLHeadingElement> = (e) => {
    const el = e.currentTarget;
    const value = !el.textContent?.trim();

    el.setAttribute('data-empty', String(value));

    if (el.textContent?.startsWith(' ')) {
      el.textContent = String(value);
    }
  };

  const handlePaste: ClipboardEventHandler<HTMLHeadingElement> = async (e) => {
    e.preventDefault();
    const text = await e.clipboardData.getData('text/plain');
    document.execCommand('insertText', false, text);
  };

  return (
    <div className={styles.container}>
      <h1
        ref={ref}
        data-empty={String(!page.title)}
        onInput={handleInput}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        onPaste={handlePaste}
        contentEditable={access.pages.edit && !page.pageSettings.lock}
        data-placeholder={t('noName')}
        className={styles.pageTitle}
        role="textbox"
        suppressContentEditableWarning
      >
        {page.title}
      </h1>
    </div>
  );
};
