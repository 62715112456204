import { useCallback } from 'react';

import { filesApi } from '@/api/files';
import { showErrorNotification } from '@/api/helpers/showNotifications';
import { Stack, Text } from '@mantine/core';
import {
  Dropzone,
  FileRejection,
  FileWithPath,
  IMAGE_MIME_TYPE,
} from '@mantine/dropzone';
import { showNotification } from '@mantine/notifications';
import { IconDownload } from '@tabler/icons-react';

import '@mantine/dropzone/styles.css';

export const CoverPopoverUpload = ({
  onUpload,
}: {
  onUpload?: (url: string) => void;
}) => {
  const [upload, { isLoading }] = filesApi.endpoints.upload.useMutation();
  const handleReject = useCallback((files: FileRejection[]) => {
    const invalidFiles = files.filter((el) =>
      el.errors.some((er) => er.code === 'file-invalid-type'),
    );

    if (invalidFiles.length > 0) {
      showNotification({
        color: 'blue',
        message: 'Данный тип файлов не поддерживается',
      });
      return;
    }

    if (
      files.some((el) => el.errors.some((er) => er.code === 'file-too-large'))
    ) {
      showErrorNotification({
        title: 'Ошибка',
        message: 'Размер файла не должен превышать 5 MB.',
      });
      return;
    }

    const tooManyFiles = files.filter((el) =>
      el.errors.some((er) => er.code === 'too-many-files'),
    );

    if (tooManyFiles.length > 0) {
      showNotification({
        message: 'Пожалуйста, загружайте не больше 1 файла.',
        color: 'blue',
      });
      return;
    }
  }, []);

  const handleDrop = useCallback(
    (files: FileWithPath[]) => {
      const file = files[0];
      if (!file) return;

      const formData = new FormData();
      formData.append('file', file, file.name);
      formData.append('isPublic', 'true');

      upload(formData)
        .unwrap()
        .then((res) => onUpload?.(res));
    },
    [onUpload, upload],
  );

  return (
    <div>
      <Dropzone
        loading={isLoading}
        onDrop={handleDrop}
        onReject={handleReject}
        maxFiles={1}
        accept={IMAGE_MIME_TYPE}
      >
        <Stack
          justify="center"
          gap={8}
          align={'center'}
          mih={200}
          style={{ pointerEvents: 'none' }}
        >
          <Text c="gray.6">
            <IconDownload size={48} strokeWidth={1.5} />
          </Text>

          <Stack gap={2} align="center">
            <Text ta="center">
              Перетащите файл сюда или нажмите, чтобы выбрать
            </Text>
            <Text c="dimmed" ta="center">
              Максимальный размер каждого файл - 5 MB
            </Text>
          </Stack>
        </Stack>
      </Dropzone>
    </div>
  );
};
