import { PropsWithChildren, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Flex,
  FloatingPosition,
  Popover,
  SegmentedControl,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
  IconBrandUnsplash,
  IconGif,
  IconLink,
  IconPhoto,
  IconPhotoPlus,
  IconUpload,
} from '@tabler/icons-react';

import { CoverPopoverGallery } from './CoverPopoverGallery';
import { CoverPopoverGiphy } from './CoverPopoverGiphy';
import { CoverPopoverLink } from './CoverPopoverLink';
import { CoverPopoverUnsplash } from './CoverPopoverUnsplash';
import { CoverPopoverUpload } from './CoverPopoverUpload';

const tabsList = ['gallery', 'upload', 'link', 'unsplash', 'giphy'] as const;

type TabType = (typeof tabsList)[number];

type CoverPopoverProps = {
  onChange?: (v: string) => void;
  onRemove?: () => void;
  showTabs?: readonly TabType[];
  position?: FloatingPosition;
  width?: number;
  readOnly?: boolean;
  hasImage?: boolean;
};

export const CoverPopover = ({
  onChange,
  onRemove,
  showTabs = tabsList,
  children,
  width = 640,
  position = 'bottom-end',
  readOnly = false,
  hasImage,
}: PropsWithChildren<CoverPopoverProps>) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 992px)');

  const [opened, setOpened] = useState(false);

  const tabs = useMemo(
    () =>
      [
        {
          value: 'unsplash',
          label: (
            <Flex>
              <IconBrandUnsplash size={16} />
              {isMobile ? null : <span>Unsplash</span>}
            </Flex>
          ),
        },
        {
          value: 'giphy',
          label: (
            <Flex>
              <IconGif size={16} />
              {isMobile ? null : <span>Giphy</span>}
            </Flex>
          ),
        },
        {
          value: 'gallery',
          label: (
            <Flex>
              <IconPhoto size={16} />
              {isMobile ? null : <span>{t('changeCover.gallery')}</span>}
            </Flex>
          ),
        },
        {
          value: 'link',
          label: (
            <Flex>
              <IconLink size={16} />
              {isMobile ? null : <span>{t('changeCover.link')}</span>}
            </Flex>
          ),
        },
        {
          value: 'upload',
          label: (
            <Flex>
              <IconUpload size={16} />
              {isMobile ? null : <span>{t('changeCover.upload')}</span>}
            </Flex>
          ),
        },
      ].filter((t) => showTabs.includes(t.value)),
    [showTabs, t, isMobile],
  );

  const [tab, setTab] = useState(tabs[0].value);

  const handleUpload = (url: string): void => {
    onChange?.(url);
    setOpened(false);
  };

  const handleChange = (url: string): void => {
    onChange?.(url);
    setOpened(false);
  };

  return (
    <Popover
      width={width}
      position={position}
      shadow="md"
      opened={opened}
      onChange={setOpened}
    >
      <Popover.Target>
        <Flex onClick={() => setOpened((o) => !o)}>
          {children ?? (
            <Button
              variant="default"
              size="xs"
              leftSection={<IconPhotoPlus size={16} />}
            >
              {t('changeCover.change')}
            </Button>
          )}
        </Flex>
      </Popover.Target>
      <Popover.Dropdown>
        <Flex justify="space-between" align="center" mb={12} w={'100%'}>
          <SegmentedControl
            value={tab}
            onChange={setTab}
            data={tabs}
            readOnly={readOnly}
          />
          {hasImage && (
            <Button variant="default" onClick={onRemove}>
              {t('changeCover.remove')}
            </Button>
          )}
        </Flex>

        {tab === 'gallery' && <CoverPopoverGallery onChange={handleChange} />}
        {tab === 'upload' && <CoverPopoverUpload onUpload={handleUpload} />}
        {tab === 'link' && <CoverPopoverLink onChange={handleChange} />}
        {tab === 'unsplash' && <CoverPopoverUnsplash onChange={handleChange} />}
        {tab === 'giphy' && <CoverPopoverGiphy onChange={handleChange} />}
      </Popover.Dropdown>
    </Popover>
  );
};
