import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { coverCollections } from '@/data/coverCollections';
import { AspectRatio, SimpleGrid, Stack, Text } from '@mantine/core';

import styles from './ItemImage.module.css';

export const CoverPopoverGallery = ({
  onChange,
}: {
  onChange?: (v: string) => void;
}) => {
  const { t } = useTranslation();

  return (
    <Stack gap={24} mah={400} w="100%" style={{ overflow: 'auto' }}>
      {coverCollections.map((category) => (
        <Stack gap={8} key={category.id}>
          <Link to={category.url}>
            <Text size="sm" c="dimmed" component="span" td={'none'}>
              {t('coverGroups.' + category.id)}
            </Text>
          </Link>

          <SimpleGrid cols={4}>
            {category.items.map((imageLink, i) => {
              const url = '/images/covers/' + category.path + imageLink;
              return (
                <AspectRatio
                  key={i}
                  onClick={() => onChange?.(url)}
                  ratio={120 / 65}
                  className={styles.itemImage}
                  style={{
                    backgroundImage: `url(${url})`,
                  }}
                />
              );
            })}
          </SimpleGrid>
        </Stack>
      ))}
    </Stack>
  );
};
