import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { WorkspaceAvatar } from '@/components/ui/WorkspaceAvatar/WorkspaceAvatar';
import { deepFilter } from '@/helpers/deepFilter';
import { useActiveWorkspace } from '@/hooks/useActiveWorkspace';
import { usePagesTree } from '@/hooks/usePagesTree';
import { Flex, Stack, Text, TextInput, Tooltip } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';

import { PageOption } from './components/PageOption';

import styles from './PageSelect.module.css';

export const PageSelect = memo(
  ({
    value,
    onChange,
    pageId,
  }: {
    value?: string | null;
    onChange?: (v: string | null) => void;
    pageId?: string | number;
  }) => {
    const activeWs = useActiveWorkspace();
    const { t } = useTranslation();
    const { complexData } = usePagesTree();
    const filteredData = useMemo(
      () => deepFilter(complexData, pageId),
      [complexData, pageId],
    );
    const [opened, setOpened] = useState<string[]>([]);

    return (
      <Stack gap={8}>
        <TextInput
          data-autofocus
          leftSection={<IconSearch size={16} />}
          placeholder={t('search')}
        />
        <Stack gap={4} mah={300} style={{ overflow: 'auto' }}>
          {activeWs && (
            <Flex
              miw={0}
              className={styles.option}
              data-active={!value}
              onClick={() => onChange?.(null)}
            >
              <WorkspaceAvatar size={16} workspace={activeWs} />
              <Tooltip label={activeWs.workspaceName}>
                <Text truncate miw={0} className={styles.title}>
                  {activeWs.workspaceName}
                </Text>
              </Tooltip>
            </Flex>
          )}
          {filteredData.map((p) => (
            <PageOption
              value={value}
              page={p}
              key={p.id}
              wsId={activeWs?.workspaceId || ''}
              opened={opened}
              onClick={(v) => onChange?.(v)}
              setOpened={setOpened}
              depth={0}
            />
          ))}
        </Stack>
      </Stack>
    );
  },
);
