import { useTranslation } from 'react-i18next';

import { useAccess } from '@/hooks/useWsProvider';
import { Button, Group, Text } from '@mantine/core';

import { openInviteMemberModal } from './InviteMemberModal';

export const InviteUsers = () => {
  const { t } = useTranslation();

  const access = useAccess();

  if (!access.members.create) return null;

  return (
    <Group content="between" w="100%" justify="space-between" mb={20}>
      <div style={{ flex: 1 }}>
        <Text size="sm" fw={500}>
          {t('utils.title')}
        </Text>
        <Text c="dimmed" size="xs" visibleFrom="lg">
          Управление членами команды и приглашениями
        </Text>
      </div>
      <Button onClick={openInviteMemberModal} variant="filled">
        {t('utils.invitePeople')}
      </Button>
    </Group>
  );
};
