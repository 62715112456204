export const HEADER_HEIGHT = 50;

export type CustomerType = 'R7' | 'DUDOC';

const env = import.meta.env;

export const CUSTOMER: CustomerType = env.VITE_BACKEND_TYPE || 'DUDOC';

export const APP_NAME = env.VITE_APP_NAME || 'Dudoc';
export const SHOW_FORMS = env.VITE_SHOW_FORMS === 'true';
export const SHOW_CUSTOM_UI = env.VITE_SHOW_CUSTOM_UI === 'true';
export const SHOW_UNSPLASH = env.VITE_SHOW_UNSPLASH === 'true';
export const SHOW_GIPHY = env.VITE_SHOW_GIPHY === 'true';
export const SHOW_LANDING = env.VITE_SHOW_LANDING === 'true';
export const SHOW_SIDEBAR_RESIZER = env.VITE_SHOW_SIDEBAR_RESIZER === 'true';
