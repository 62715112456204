import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMenu } from '@/components/layout/hooks/useMenu';
import { openCreatePageModal } from '@/features/CreatePageModal/CreatePageModal';
import { useActiveWorkspace } from '@/hooks/useActiveWorkspace';
import { usePagesTree } from '@/hooks/usePagesTree';
import { useAccess } from '@/hooks/useWsProvider';
import { ActionIcon, Flex, Stack, Text } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';

import { DraggablePageLinksGroup } from '../../PageLink/DraggablePageLinksGroup';

import styles from '../AppNavMenu.module.css';

export const PagesMenu = () => {
  const { t } = useTranslation();

  const access = useAccess();
  const activeWs = useActiveWorkspace();
  const { isBurger, close } = useMenu();
  const { complexData } = usePagesTree();

  const [opened, setOpened] = useState<string[]>([]);

  return (
    <Stack px={16} py={16}>
      <Stack gap={8}>
        <Text className={styles.sectionTitle} size="sm" fw={500} c="dimmed">
          {t('myPages')}
        </Text>
        <div>
          <DraggablePageLinksGroup
            pages={complexData}
            opened={opened}
            setOpened={setOpened}
            depth={0}
          />
          {access.pages.create && (
            <div
              onClick={() => {
                openCreatePageModal({
                  workspaceId: activeWs?.workspaceId || '',
                });
                isBurger && close();
              }}
            >
              <Flex mt={10} gap={4} pl={0} c="dimmed">
                <ActionIcon size="sm" color="dimmed" variant="transparent">
                  <IconPlus size={16} />
                </ActionIcon>
                <Text size="sm" fw={500} style={{ cursor: 'pointer' }}>
                  {t('addPage')}
                </Text>
              </Flex>
            </div>
          )}
        </div>
      </Stack>
    </Stack>
  );
};
