import { Dispatch, Fragment, SetStateAction } from 'react';

import { ComplexHierarchyPageData } from '@/hooks/usePagesTree';

import { Draggable } from './Draggable';
import { Droppable } from './Droppable';
import { DroppableLine } from './DroppableLine';
import { PageLink } from './PageLink';

export const DraggablePageLinksGroup = ({
  pages,
  depth,
  opened,
  setOpened,
}: {
  pages: ComplexHierarchyPageData[];
  opened: string[];
  depth: number;
  setOpened: Dispatch<SetStateAction<string[]>>;
}) => {
  return (
    <div style={{ position: 'relative' }}>
      {pages.map((p, i) => {
        const isOpened = opened.includes(p.id) && p.children.length > 0;
        return (
          <Fragment key={p.id}>
            {i === 0 && <DroppableLine page={p} order={i} />}
            <Draggable page={p}>
              <Droppable page={p}>
                <PageLink
                  page={p}
                  key={p.id}
                  opened={opened}
                  setOpened={setOpened}
                  depth={depth}
                />
              </Droppable>
            </Draggable>

            {isOpened && (
              <DraggablePageLinksGroup
                depth={depth + 1}
                opened={opened}
                pages={p.children}
                setOpened={setOpened}
              />
            )}

            {!isOpened && <DroppableLine page={p} order={i + 1} />}
          </Fragment>
        );
      })}
    </div>
  );
};
