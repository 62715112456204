import { formsApi } from '@/api/forms';
import { workspacesApi } from '@/api/workspaces';
import { appRoute } from '@/app/routes/AppRoute';
import { store } from '@/app/store';
import { openRemoveConfirmModal } from '@/components/ui/confirmModals/RemoveConfirmModal';
import { notifications } from '@mantine/notifications';

export const removePage = (
  pageTitle: string,
  pageId: string,
  opts?: {
    redirect?: boolean;
    permanent?: boolean;
  },
) => {
  openRemoveConfirmModal({
    title: 'Удалить страницу?',
    children: opts?.permanent
      ? 'Страница будет удалена навсегда без возможности восстановления.'
      : undefined,
    labels: {
      cancel: 'Отмена',
      confirm: 'Да, удалить',
    },
    onConfirm: () => {
      try {
        store.dispatch(
          workspacesApi.endpoints.deletePage.initiate({
            id: pageId,
            permanent: opts?.permanent,
          }),
        );
        if (opts?.permanent) {
          notifications.show({
            message: `Страница '${pageTitle || ' '}' удалена`,
          });
        } else {
          notifications.show({
            message: `Страница '${pageTitle || ' '}' перемещена в корзину`,
          });
        }
      } catch {
        notifications.show({
          message: `Не получилось удалить страницу '${pageTitle || ' '}'`,
        });
      }

      if (opts?.redirect) {
        appRoute.navigate('/', { replace: true });
      }
    },
  });
};

export const removeForm = (
  formId: string,
  opts?: {
    redirect?: boolean;
    permanent?: boolean;
  },
) => {
  openRemoveConfirmModal({
    title: 'Удалить форму?',
    children: 'Форма будет удалена навсегда без возможности восстановления.',
    labels: {
      cancel: 'Не удалять',
      confirm: 'Да, удалить',
    },
    onConfirm: () => {
      store.dispatch(
        formsApi.endpoints.deleteForm.initiate({
          id: formId,
        }),
      );

      if (opts?.redirect) {
        appRoute.navigate('/', { replace: true });
      }
    },
  });
};
