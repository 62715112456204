import { Suspense, useEffect, useState } from 'react';

import { filesApi } from '@/api/files';
import { showErrorNotification } from '@/api/helpers/showNotifications';
// import { getAccessToken } from '@/api/helpers/token';
import { store } from '@/app/store';
import { CUSTOMER } from '@/config/constants';
import { useCurrentUserData } from '@/hooks/useCurrentUserData';
import { useAvatarColor } from '@sinups/agg';
import { ActiveUserProfile, Editor, Root } from '@sinups/editor-dsd';
import { isString } from 'lodash-es';

import { BasePageData } from '../CreatePageModal/CreatePageModal';
import { activeUsersSlice } from './slice';

export const TextEditor = <PAGE extends Pick<BasePageData, 'id' | 'content'>>({
  page,
  handleContent,
}: {
  page: PAGE;
  handleContent?: (v: string) => void;
}) => {
  const [value, setValue] = useState<string>(page?.content.html || '');
  const [upload] = filesApi.endpoints.upload.useMutation();
  const user = useCurrentUserData();
  const color = useAvatarColor(user?.id ?? '');

  //   const accessToken = getAccessToken();
  const isNewPage = page?.id === 'new_page';

  const fetchUploadImage = async (
    file: File,
    success: (ulr: string, id: string) => void,
    error?: (error?: Error) => void,
  ) => {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('isPublic', 'true');

    return upload(formData)
      .then((res) => {
        if (!res.data) throw new Error('File upload failed');
        success(res.data, res.data.toString());
      })
      .catch((err) => {
        if (error) {
          if (err instanceof Error) {
            error(err);
          } else {
            error(new Error('An unknown error occurred'));
          }
        }
      });
  };

  useEffect(() => {
    setValue(page?.content.html || '');
  }, [page.content]);

  const handleChange = (value: Root | string) => {
    if (isString(value)) {
      handleContent?.(value);
    }
  };

  const getAdditionalProps = () => {
    if (CUSTOMER === 'DUDOC') {
      return {
        ws: {
          // url: `https://wss.dudoc.io/?token=${accessToken}`,
          // url: `http://localhost:1234/?token=${accessToken}`,
          id: page.id,
          user: { name: user.name, color },
          getActiveUsers: (users: ActiveUserProfile[]) => {
            store.dispatch(activeUsersSlice.actions.setActiveUsers(users));
          },
        },
        excludeNodes: {
          form: true,
        },
      };
    }

    return {
      excludeNodes: {
        form: true,
      },
    };
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Editor
        onChange={handleChange}
        debounce={isNewPage ? 1 : 800}
        fetchUploadImage={fetchUploadImage}
        maxImageSizeError={() =>
          showErrorNotification({
            message: 'Превышен допустимый размер',
          })
        }
        maxImageSize={25}
        initialContent={value}
        outputFormat={'html'}
        mode={'editor'}
        {...getAdditionalProps()}
      />
    </Suspense>
  );
};
