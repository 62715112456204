import { ReactNode } from 'react';

import Icon404 from '@/assets/svg/noData/404.svg?react';
import Icon500 from '@/assets/svg/noData/500.svg?react';
import AddDataDudocIcon from '@/assets/svg/noData/addData-dudoc.svg?react';
import AddDataR7Icon from '@/assets/svg/noData/addData-r7.svg?react';
import EmptyIcon from '@/assets/svg/noData/empty.svg?react';
import FolderIcon from '@/assets/svg/noData/folder.svg?react';
import NoAccessIcon from '@/assets/svg/noData/noAccess.svg?react';
import noCommentsIcon from '@/assets/svg/noData/noComments.svg?react';
import NoDataIcon from '@/assets/svg/noData/noData.svg?react';
import NoImageIcon from '@/assets/svg/noData/noImage.svg?react';
import NoResultsIcon from '@/assets/svg/noData/noResults.svg?react';
import NoTaskResultsIcon from '@/assets/svg/noData/noTaskResults.svg?react';
import NoTasksIcon from '@/assets/svg/noData/noTasks.svg?react';
import TrashIcon from '@/assets/svg/noData/trash.svg?react';
import { CUSTOMER } from '@/config/constants';
import {
  Button,
  ButtonProps,
  Flex,
  Group,
  PolymorphicComponentProps,
  Stack,
  StackProps,
  Text,
  TextProps,
  Title,
  TitleProps,
  useComputedColorScheme,
} from '@mantine/core';

export const illustrationMap = {
  empty: EmptyIcon,
  noAccess: NoAccessIcon,
  trash: TrashIcon,
  noImage: NoImageIcon,
  noResults: NoResultsIcon,
  noTasks: NoTasksIcon,
  noComments: noCommentsIcon,
  noData: NoDataIcon,
  addData: CUSTOMER === 'R7' ? AddDataR7Icon : AddDataDudocIcon,
  emptyFolder: FolderIcon,
  noTaskResults: NoTaskResultsIcon,
  '404': Icon404,
  '500': Icon500,
};

type IllustrationType = keyof typeof illustrationMap;

interface NoDataProps {
  title?: ReactNode;
  titleProps?: TitleProps;
  description?: ReactNode;
  descriptionProps?: TextProps;
  buttons?: PolymorphicComponentProps<'button', ButtonProps>[];
  illustration?: IllustrationType;
  size?: number;
}

export const NoData: React.FC<NoDataProps & StackProps> = ({
  title,
  titleProps,
  description,
  descriptionProps,
  buttons,
  illustration,
  size = 240,
  ...stackProps
}) => {
  const colorScheme = useComputedColorScheme();

  const IllustrationImage = illustration
    ? illustrationMap[illustration]
    : EmptyIcon;

  return (
    <Stack gap="sm" justify="center" flex={1} align="center" {...stackProps}>
      <Flex
        justify="center"
        style={{
          height: size,
          width: size,
          ...(colorScheme === 'dark' ? { filter: 'brightness(0.7)' } : {}),
        }}
      >
        <IllustrationImage />
      </Flex>
      {title && (
        <Title order={2} ta="center" maw={400} {...titleProps}>
          {title}
        </Title>
      )}
      {description && (
        <Text fz="md" ta="center" c="dimmed" maw={400} {...descriptionProps}>
          {description}
        </Text>
      )}
      {buttons?.length ? (
        <Group gap="xs" justify="center">
          {buttons.map((button, index) => (
            <Button key={index} variant="filled" {...button} />
          ))}
        </Group>
      ) : null}
    </Stack>
  );
};
