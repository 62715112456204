import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';

import { APP_NAME, SHOW_CUSTOM_UI } from '@/config/constants';
import { AppRoutes } from '@/config/routes';
import { WorkspaceProvider } from '@/hooks/useWsProvider';
import { Box, Button, Flex, Text } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';

import {
  AppLayout,
  NavbarHeader,
  NavbarScrollableContent,
} from '../AppLayout/AppLayout';
import { useNavbarScroll } from '../AppLayout/useNavbarScroll';
import { SettingsNavMenu } from './components/SettingsNavMenu';

export const AppRouteSettingsLayout = () => {
  return (
    <>
      <Helmet>
        <title>Настройки | {APP_NAME}</title>
      </Helmet>
      <WorkspaceProvider>
        <AppLayout
          content={<Outlet />}
          header={<AppHeader />}
          navbar={<AppNavbar />}
        />
      </WorkspaceProvider>
    </>
  );
};

const AppHeader = () => {
  const isProfile = useMatch(AppRoutes.settingsAccount);
  const isWorkspace = useMatch(AppRoutes.settingsWorkspace);
  const isMembers = useMatch(AppRoutes.settingsMembers);
  const settingsPublish = useMatch(AppRoutes.settingsPublish);

  return (
    <Flex h="100%" flex={1}>
      <Text size="sm" c="dimmed" mr={8}>
        {isProfile ? 'Профиль' : null}
        {isWorkspace ? 'Пространство' : null}
        {isMembers ? 'Участники' : null}
        {settingsPublish && SHOW_CUSTOM_UI ? 'Внешний вид' : null}
      </Text>
    </Flex>
  );
};

const AppNavbar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { onScroll, showDivider } = useNavbarScroll();

  return (
    <>
      <NavbarHeader showDivider={showDivider}>
        <Button
          variant="subtle"
          color="dark"
          fullWidth
          justify="flex-start"
          onClick={() => navigate('/')}
          leftSection={<IconArrowLeft size={16} />}
        >
          {t('settingsPage.goBack')}
        </Button>
      </NavbarHeader>

      <NavbarScrollableContent onScroll={onScroll}>
        <Box p={16}>
          <SettingsNavMenu />
        </Box>
      </NavbarScrollableContent>
    </>
  );
};
