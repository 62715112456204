import { ReactNode, useState } from 'react';

import {
  Badge,
  Box,
  Card,
  Collapse,
  Flex,
  Group,
  Switch,
  Text,
} from '@mantine/core';

import classes from './styles.module.css';

interface SettingsCardProps {
  title?: string | ReactNode;
  description?: string;
  currentPlan?: string;
  requiredPlan?: string;
  isSwitchEnabled: boolean;
  defaultContentVisible: boolean;
  children: ReactNode;
  buttons: ReactNode[];
  onSwitchChange?: (checked: boolean) => void;
}

export const SettingsCard = ({
  title,
  description,
  currentPlan,
  requiredPlan,
  isSwitchEnabled,
  defaultContentVisible,
  children,
  buttons,
  onSwitchChange,
}: SettingsCardProps) => {
  const [isContentVisible, setIsContentVisible] = useState(
    defaultContentVisible,
  );

  const handleToggle = (checked: boolean) => {
    setIsContentVisible(checked);
    if (onSwitchChange) {
      onSwitchChange(checked);
    }
  };

  const isPlanMatch = !requiredPlan || currentPlan === requiredPlan;

  return (
    <Card padding="0" radius="md" withBorder mb="lg">
      <Group
        content="between"
        w="100%"
        justify="space-between"
        p={'sm'}
        className={isContentVisible ? classes.root : classes.rootReadMode}
      >
        <Box style={{ flex: 1 }}>
          <Flex>
            <Text size="sm" fw={500}>
              {title}
            </Text>
            {requiredPlan && (
              <Badge
                variant="light"
                size="md"
                color="grape"
                ml="sm"
                tt="capitalize"
              >
                {requiredPlan}
              </Badge>
            )}
          </Flex>
          <Text c="dimmed" size="xs" visibleFrom="lg">
            <>{description}</>
          </Text>
        </Box>
        {isSwitchEnabled && (
          <Switch
            value="setting_block"
            labelPosition="left"
            color="light-dark(var(--mantine-color-gray-9), var(--mantine-color-dark-3))"
            checked={isContentVisible}
            onChange={(event) => handleToggle(event.currentTarget.checked)}
            disabled={!isPlanMatch}
          />
        )}
      </Group>

      <Collapse in={isContentVisible}>
        <>
          <Box p="sm">{children}</Box>
          <Box px="sm" className={classes.footer}>
            <Flex gap="md" justify="flex-end" py="sm">
              {buttons.map((button, index) => (
                <div key={index}>{button}</div>
              ))}
            </Flex>
          </Box>
        </>
      </Collapse>
    </Card>
  );
};
