import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { showRequestNotifications } from '@/api/helpers/showNotifications';
import { workspacesApi } from '@/api/workspaces';
import { useForm } from '@mantine/form';
import { modals } from '@mantine/modals';

import { useActiveWorkspace } from './useActiveWorkspace';

type FormValues = {
  avatarUrl: string;
  name: string;
};

const useWorkspaceForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [remove] = workspacesApi.endpoints.deleteWorkspace.useMutation();
  const [update] = workspacesApi.endpoints.updateWorkspace.useMutation();

  const [isSaved, setIsSaved] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const activeWs = useActiveWorkspace();

  const form = useForm<FormValues>({
    initialValues: {
      avatarUrl: activeWs?.avatarUrl || '',
      name: activeWs?.workspaceName || '',
    },

    validate: {
      name: (v) => {
        const trimmedValue = (v || '').trim();
        if (trimmedValue.length === 0) {
          return t('errors.EMPTY_FIELD');
        }
        if (trimmedValue.length > 50) {
          return t('errors.MAX_LENGTH_EXCEEDED', { max: 50 });
        }
        return null;
      },
    },
  });

  useEffect(() => {
    if (activeWs) {
      form.setValues({
        avatarUrl: activeWs?.avatarUrl || '',
        name: activeWs?.workspaceName || '',
      });
      form.resetDirty();
      setIsSaved(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeWs]);

  useEffect(() => {
    if (form.isDirty()) {
      setIsSaved(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values]);

  const handleSubmit = async (values: FormValues) => {
    setIsSubmitting(true);

    try {
      const result = update({
        avatarUrl: values.avatarUrl,
        name: values.name,
        workspaceId: activeWs?.workspaceId || '',
      }).unwrap();

      showRequestNotifications(result, {
        successMsg: 'Пространство было успешно обновлено',
        errorMsg: 'Не удалось обновить пространство',
      });
      setIsSaved(true);
      form.resetDirty();
    } catch (error) {
      showRequestNotifications(Promise.reject(error), {
        notifyId: 'update-workspace',
        errorMsg: 'Не удалось обновить пространство',
      });
      console.error('Failed to update workspace:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleRemove = () => {
    modals.openConfirmModal({
      title: 'Удалить пространство навсегда ?',
      children:
        'Это действие невозможно отменить. Это навсегда удалит пространство, включая все страницы.',
      labels: {
        confirm: 'Удалить навсегда',
        cancel: 'Отмена',
      },
      onConfirm: () => {
        remove({
          workspaceId: activeWs?.workspaceId || '',
        })
          .unwrap()
          .then(() => {
            navigate('/', { replace: true });
          });
      },
    });
  };

  return {
    form,
    handleSubmit,
    handleRemove,
    isSaved,
    isSubmitting,
  };
};

export default useWorkspaceForm;
