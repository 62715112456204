import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { showErrorNotification } from '@/api/helpers/showNotifications';
import { ApiPages, workspacesApi } from '@/api/workspaces';
import { appRoute } from '@/app/routes/AppRoute';
import {
  AppLayout,
  NavbarHeader,
  NavbarScrollableContent,
} from '@/components/layout/AppLayout/AppLayout';
import { useNavbarScroll } from '@/components/layout/AppLayout/useNavbarScroll';
import { PublishedPageLinksGroup } from '@/components/layout/Sidebar/components/PageLink/PublishedPageLinksGroup';
import { Logotype } from '@/components/ui/Logotype';
import { NoData } from '@/components/ui/NoData/NoData';
import { APP_NAME } from '@/config/constants';
import { PageBody } from '@/features/PageBody/PageBody';
import { PublishedPageProvider } from '@/hooks/useWsProvider';
import { Box, Skeleton, Stack } from '@mantine/core';
import { useDebouncedCallback } from '@mantine/hooks';

import { CryptedLayout } from './CryptedLayout';

export const PublishedPage = () => {
  const { t } = useTranslation();
  const { pageId } = useParams();
  const [pin, setPin] = useState('');
  const [pageUnlocked, setPageUnlocked] = useState(false);
  const [rootId, setRootId] = useState<string | null>();
  const [tree, setTree] = useState<ApiPages.HierarchyPageData>();
  const [data, setData] = useState<ApiPages.GetPublishedPageResponse | null>();
  const [error, setError] = useState(false);

  const [getPage, { isLoading }] =
    workspacesApi.endpoints.getPublicPageById.useLazyQuery();

  useEffect(() => {
    if (!pageId) return;
    getPage({ pageId })
      .unwrap()
      .then((res) => {
        setData(res);
        setRootId(res.rootParentId);
      })
      .catch(() => setError(true));
  }, [getPage, pageId]);

  const handleUnlock = useCallback(() => {
    if (!pageId) return;
    getPage({ pageId, pin })
      .unwrap()
      .then((res) => {
        setData({ ...res, document: { ...res.document, iscrypted: false } });
        setError(false);
        setPageUnlocked(true);
      })
      .catch((err) => {
        if (err?.data?.code === 'ERR10000') {
          showErrorNotification({
            message: 'Неверный пароль',
          });
        } else {
          setError(true);
        }
      });
  }, [getPage, pageId, pin]);

  useEffect(() => {
    if (!rootId) return;
    getPage({ pageId: rootId })
      .unwrap()
      .then((res) => {
        setTree(res.tree);
      })
      .catch(() => setError(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootId]);

  const debouncedHandleUnlock = useDebouncedCallback(handleUnlock, 400);

  if (error) {
    return (
      <>
        <Helmet>
          <title>Страница не найдена | {APP_NAME}</title>
        </Helmet>
        <NoData
          illustration="404"
          title={'Страница не найдена'}
          descriptionProps={{ maw: 600 }}
          description={'Возможно, страница была перемещена или удалена.'}
          buttons={[
            {
              children: 'Вернуться на главную',
              onClick: () => appRoute.navigate('/'),
            },
          ]}
        />
      </>
    );
  }

  if (!data) {
    return (
      <Stack>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Stack>
    );
  }

  if (data.document.iscrypted && !pageUnlocked) {
    return (
      <>
        <Helmet>
          <title>
            {data.document.icon || ''} {data.document.title || t('noName')} |{' '}
            {APP_NAME}
          </title>
        </Helmet>
        <CryptedLayout
          pin={pin}
          setPin={setPin}
          onUnlock={debouncedHandleUnlock}
          handleUnlock={handleUnlock}
          isLoading={isLoading}
          data={data}
        />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {data.document.icon || ''} {data.document.title || t('noName')} |{' '}
          {APP_NAME}
        </title>
      </Helmet>
      <PublishedPageProvider>
        <AppLayout
          hideUserMenu
          navbar={<Navbar tree={tree} />}
          content={<Content data={data} />}
          header={<></>}
        />
      </PublishedPageProvider>
    </>
  );
};

const Navbar = ({ tree }: { tree: ApiPages.HierarchyPageData | undefined }) => {
  const { onScroll, showDivider } = useNavbarScroll();
  const [opened, setOpened] = useState<string[]>([]);

  return (
    <>
      <NavbarHeader showDivider={showDivider}>
        <Box pl={8}>
          <Link to={'/'}>
            <Logotype w={100} />
          </Link>
        </Box>
      </NavbarHeader>

      <NavbarScrollableContent onScroll={onScroll}>
        <Box p={16}>
          {tree && (
            <PublishedPageLinksGroup
              pages={[tree as any]}
              opened={opened}
              setOpened={setOpened}
              depth={0}
            />
          )}
        </Box>
      </NavbarScrollableContent>
    </>
  );
};

export const Content = ({
  data,
}: {
  data: ApiPages.GetPublishedPageResponse;
}) => {
  const page = data.document;
  return <PageBody page={page} />;
};
