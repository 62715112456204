import { modals } from '@mantine/modals';
import { OpenConfirmModal } from '@mantine/modals/lib/context';

export const openRemoveConfirmModal = (props: OpenConfirmModal) =>
  modals.openConfirmModal({
    keepMounted: false,
    centered: true,
    labels: { confirm: 'Удалить', cancel: 'Отмена' },
    confirmProps: {
      color: 'red',
      fullWidth: true,
    },
    cancelProps: {
      fullWidth: true,
    },
    groupProps: {
      wrap: 'nowrap',
    },
    ...props,
  });
