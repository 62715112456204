import { DragOverlay, useDndContext } from '@dnd-kit/core';

import styles from './PageLink.module.css';

export const DraggableOverlay = () => {
  const { active } = useDndContext();

  return (
    <DragOverlay dropAnimation={null}>
      {active ? (
        <div className={styles.dragOverlay}>
          <span>{active.data.current?.icon}</span>
          {active.data.current?.title}
        </div>
      ) : null}
    </DragOverlay>
  );
};
