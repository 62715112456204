import { useMemo } from 'react';

import { ApiForms } from '@/api/forms';
import { appRoute } from '@/app/routes/AppRoute';
import { NoData } from '@/components/ui/NoData/NoData';
import { controlsDataMap } from '@/forms/FormEditor/ControlsBar/ControlItem';
import { ControlType } from '@/forms/types/form';
import { useSubmitDataForm } from '@/hooks/useSubmitDataForm';
import {
  ActionIcon,
  Badge,
  Container,
  Flex,
  NumberFormatter,
  Paper,
  Skeleton,
  Stack,
  Switch,
  Table,
  Text,
  ThemeIcon,
  Tooltip,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconCheck, IconEye, IconX } from '@tabler/icons-react';
import { format } from 'date-fns';
import { isArray, isNil, isString, uniqBy } from 'lodash-es';

type ColumnDef = {
  stepId: string;
  title: string;
  controlType: ControlType;
};

type RowDef = {
  submitId: string;
  submittedAt: string;
  data: Record<string, any>;
};

const Content = ({ data }: { data: ApiForms.IGetByIdSubmitResponse }) => {
  const { columns, rows } = useMemo(() => {
    const columns = data.reduce<ColumnDef[]>((acc, submit) => {
      const data = JSON.parse(submit.data) as ApiForms.StepSubmitData[];

      data.forEach((step) => {
        acc.push({
          stepId: step.id,
          title: step.title,
          controlType: step.type,
        });
      });

      return acc;
    }, []);

    const rows = data.reduce<RowDef[]>((acc, submit) => {
      const submitData = JSON.parse(submit.data) as ApiForms.StepSubmitData[];

      const rowData = submitData.reduce<Record<string, any>>((acc, step) => {
        acc[step.id] = step.value;
        return acc;
      }, {});

      acc.push({
        data: rowData,
        submitId: submit.id,
        submittedAt: submit.submittedAt,
      });

      return acc;
    }, []);

    return {
      columns: uniqBy(columns, 'stepId'),
      rows,
    };
  }, [data]);

  return (
    <Stack p={16} gap={16}>
      <Switch label="Отображать удаленные шаги" />
      <Paper withBorder radius={'md'} style={{ overflow: 'hidden' }}>
        <Table.ScrollContainer minWidth={500}>
          <Table
            stickyHeader
            striped
            highlightOnHover
            withRowBorders={false}
            horizontalSpacing="md"
            verticalSpacing="md"
          >
            <Table.Thead>
              <Table.Tr>
                <Table.Th>
                  <Text fz="sm" style={{ whiteSpace: 'nowrap' }}>
                    Дата отправления
                  </Text>
                </Table.Th>
                {columns.map((c) => (
                  <Table.Th key={c.stepId}>
                    <Flex>
                      <ThemeIcon size="sm">
                        {controlsDataMap[c.controlType].icon}
                      </ThemeIcon>
                      <Tooltip label={c.title} openDelay={400}>
                        <Text
                          fz="sm"
                          maw={300}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          {c.title}
                        </Text>
                      </Tooltip>
                    </Flex>
                  </Table.Th>
                ))}
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {rows.map((r) => (
                <Table.Tr key={r.submitId}>
                  <Table.Td>
                    <Text fz="sm" style={{ whiteSpace: 'nowrap' }}>
                      {format(r.submittedAt, 'dd MMM yyyy, HH:MM')}
                    </Text>
                  </Table.Td>
                  {columns.map((c) => {
                    const value = r.data[c.stepId];

                    if (isNil(value) || (isString(value) && !value))
                      return <Table.Td key={c.stepId}></Table.Td>;

                    if (c.controlType === 'options' && isArray(value)) {
                      return (
                        <Table.Td key={c.stepId}>
                          <Flex wrap={'wrap'}>
                            {value.map((opt) => (
                              <Badge key={opt} variant="light" size="sm">
                                {opt}
                              </Badge>
                            ))}
                          </Flex>
                        </Table.Td>
                      );
                    }

                    if (c.controlType === 'choice') {
                      return (
                        <Table.Td key={c.stepId}>
                          <Badge variant="light" size="sm">
                            {value}
                          </Badge>
                        </Table.Td>
                      );
                    }

                    if (c.controlType === 'dropdown') {
                      return (
                        <Table.Td key={c.stepId}>
                          <Badge variant="light" size="sm">
                            {value}
                          </Badge>
                        </Table.Td>
                      );
                    }

                    if (
                      c.controlType === 'longText' ||
                      c.controlType === 'text' ||
                      c.controlType === 'location'
                    ) {
                      return (
                        <Table.Td key={c.stepId}>
                          <Flex maw={300}>
                            <Text
                              truncate
                              fz="sm"
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              {value}
                            </Text>
                            <ActionIcon
                              variant="subtle"
                              size="sm"
                              onClick={() => {
                                modals.open({
                                  withCloseButton: false,
                                  children: value,
                                });
                              }}
                            >
                              <IconEye size={16} />
                            </ActionIcon>
                          </Flex>
                        </Table.Td>
                      );
                    }

                    if (c.controlType === 'date') {
                      return (
                        <Table.Td key={c.stepId}>
                          <Text fz="sm" style={{ whiteSpace: 'nowrap' }}>
                            {format(value, 'dd MMM yyyy, HH:MM')}
                          </Text>
                        </Table.Td>
                      );
                    }

                    if (
                      c.controlType === 'number' ||
                      c.controlType === 'slider'
                    ) {
                      return (
                        <Table.Td key={c.stepId} align="right">
                          <NumberFormatter value={value} thousandSeparator />
                        </Table.Td>
                      );
                    }

                    if (c.controlType === 'yesNo') {
                      return (
                        <Table.Td key={c.stepId} align="center">
                          {value ? (
                            <IconCheck size={16} />
                          ) : (
                            <IconX size={16} />
                          )}
                        </Table.Td>
                      );
                    }
                    return <Table.Td key={c.stepId}>{value}</Table.Td>;
                  })}
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>
      </Paper>
    </Stack>
  );
};

export const ResultsFormPage = () => {
  const form = useSubmitDataForm();

  if (!form) return;

  const { data, isLoading, isError, isSuccess } = form;

  if (isLoading) {
    return (
      <Stack p={16} px={48}>
        <Skeleton h={24} />
        <Skeleton h={24} />
        <Skeleton h={24} />
        <Skeleton h={24} />
      </Stack>
    );
  }

  if (isError || !isSuccess) {
    return (
      <Container
        style={{
          height: '100dvh',
          display: 'flex',
          paddingTop: '10dvh',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <NoData
          title={'Форма не найдена'}
          descriptionProps={{ maw: 600 }}
          description={'Возможно, страница была перемещена или удалена.'}
          buttons={[
            {
              children: 'Вернуться на главную',
              onClick: () => appRoute.navigate('/'),
            },
          ]}
        />
      </Container>
    );
  }

  return <Content data={data} />;
};
