import { useTranslation } from 'react-i18next';

import { isValidUrl } from '@/helpers/general';
import { Button, Center, Stack, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconPhoto } from '@tabler/icons-react';

type CoverPopoverLinkProps = {
  onChange?: (v: string) => void;
};

export const CoverPopoverLink = ({ onChange }: CoverPopoverLinkProps) => {
  const { t } = useTranslation();

  const form = useForm<{ url: string }>({
    initialValues: {
      url: '',
    },
    validate: {
      url: (v) =>
        (v || '').trim().length > 0 && isValidUrl(v)
          ? null
          : t('changeCover.invalidLink'),
    },
  });

  const handleSubmit = (values: typeof form.values) => {
    onChange?.(values.url);
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack gap={16}>
        <TextInput
          maxLength={500}
          placeholder={t('changeCover.pasteLink')}
          data-autofocus
          {...form.getInputProps('url')}
        />

        <Center>
          <Button leftSection={<IconPhoto size={16} />} type="submit">
            {t('changeCover.submitLink')}
          </Button>
        </Center>

        <Center>
          <Text size="sm" c="dimmed">
            {t('changeCover.worksWithAnyImage')}
          </Text>
        </Center>
      </Stack>
    </form>
  );
};
