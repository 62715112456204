import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { getCurrentUserId } from '@/api/helpers/token';
import { workspacesApi } from '@/api/workspaces';
import { Logotype, getLogoSize } from '@/components/ui/Logotype';
import { WorkspaceAvatar } from '@/components/ui/WorkspaceAvatar/WorkspaceAvatar';
import {
  Button,
  Flex,
  Group,
  LoadingOverlay,
  Stack,
  Text,
  Title,
} from '@mantine/core';

type invitedWorkspaceType = {
  name: string;
  avatarUrl: string;
  id: string;
  icon: string;
  invitedEmail: string;
};

const InvitationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const userId = getCurrentUserId();

  const [getInvitedWorkspaceInfo, { isLoading }] =
    workspacesApi.endpoints.getInvitationInfo.useMutation();
  const [acceptInvitation] =
    workspacesApi.endpoints.acceptInvitation.useMutation();

  const [inviteToken, setInviteToken] = useState<string>('');
  const [invitedWorkspace, setInvitedWorkspace] =
    useState<invitedWorkspaceType>({
      name: ' ',
      avatarUrl: ' ',
      id: ' ',
      icon: ' ',
      invitedEmail: ' ',
    });
  const [invitationMessage, setInvitationMessage] = useState<string>(
    t('invitationPage.invitationMessage'),
  );
  const [isTokenValid, setIsTokenValid] = useState<boolean>(true);

  const handleAcceptInvitation = async () => {
    try {
      const data = await acceptInvitation({
        inviteToken: inviteToken,
      }).unwrap();
      if (data.success) {
        navigate(`/`);
      }
    } catch (err: any) {
      if (err.data.message === 'Email mismatch') {
        setIsTokenValid(false);
        setInvitationMessage(t('invitationPage.emailMismatch'));
        setInvitedWorkspace({
          name: t('invitationPage.invalidEmail'),
          avatarUrl: ' ',
          id: ' ',
          icon: ' ',
          invitedEmail: ' ',
        });
        console.error(err.data);
      } else if (err.data.code === 'ERR42') {
        setIsTokenValid(false);
        setInvitedWorkspace({
          name: 'Неактуально',
          avatarUrl: ' ',
          id: ' ',
          icon: ' ',
          invitedEmail: ' ',
        });
        setInvitationMessage(t('invitationPage.linkExpired'));
        console.error(err.data);
      }
    }
  };

  const handleInvitedWorkspaceInfo = async () => {
    const inviteToken = location.pathname.split('/')[2];
    setInviteToken(inviteToken);
    try {
      const data = await getInvitedWorkspaceInfo({ inviteToken }).unwrap();
      setInvitedWorkspace(data);
    } catch (err: any) {
      console.error(err);
      if (err.data.code === 'ERR42') {
        setIsTokenValid(false);
        setInvitedWorkspace({
          name: 'Неактуально',
          avatarUrl: ' ',
          id: ' ',
          icon: ' ',
          invitedEmail: ' ',
        });
        setInvitationMessage(t('invitationPage.linkExpired'));
      }
    }
  };

  useEffect(() => {
    handleInvitedWorkspaceInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Group
      style={{
        height: '100dvh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Logotype
        w={getLogoSize(false).w}
        h={getLogoSize(false).h}
        style={{ position: 'absolute', top: 12, left: 45 }}
      />
      <LoadingOverlay
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
        visible={isLoading}
      />

      <Stack
        style={{
          width: '600px',
          padding: '20px',
          backgroundColor: 'white',
          borderRadius: '8px',
        }}
        align="center"
        gap="md"
      >
        <WorkspaceAvatar
          workspace={{
            workspaceId: invitedWorkspace.id,
            workspaceName: invitedWorkspace.name,
            avatarUrl: invitedWorkspace.avatarUrl,
          }}
          tt={'uppercase'}
          size={48}
        />
        <Title mih={40} fw={600} ta="center">
          {invitedWorkspace.name}
        </Title>
        <Text w={isTokenValid ? 270 : 370} ta="center" size="md" c="black">
          {invitationMessage}
          {isTokenValid && '«' + invitedWorkspace.name + '»'}
        </Text>
        {userId === -1 ? (
          <Flex align="center" justify={'center'} gap={10}>
            <Button
              variant="transparent"
              w={204}
              size="md"
              onClick={() =>
                navigate(
                  `/register?returnUrl=${encodeURIComponent(location.pathname + location.search)}`,
                )
              }
            >
              {t('invitationPage.createAccount')}
            </Button>
            <Button
              variant="filled"
              size="md"
              w={204}
              onClick={() =>
                navigate(
                  `/login?returnUrl=${encodeURIComponent(location.pathname + location.search)}`,
                )
              }
            >
              {t('invitationPage.login')}
            </Button>
          </Flex>
        ) : (
          <Button
            disabled={!isTokenValid}
            onClick={handleAcceptInvitation}
            size="md"
          >
            {t('invitationPage.join')}
          </Button>
        )}
      </Stack>
    </Group>
  );
};

export default InvitationPage;
