import { ChangeEventHandler, ReactNode } from 'react';

import { CoverPopover } from '@/features/PageCover/CoverPopover';
import {
  DEFAULT_MEDIA_BRIGHTNESS,
  DEFAULT_MEDIA_SIZE,
  sizeableMediaLayouts,
} from '@/forms/helpers/steps';
import {
  Align,
  MediaLayout,
  alignList,
  mediaLayoutList,
} from '@/forms/types/form';
import {
  ActionIcon,
  Avatar,
  Flex,
  InputLabel,
  Slider,
  Stack,
  TextInput,
  Tooltip,
} from '@mantine/core';
import {
  IconAlignBoxCenterMiddleFilled,
  IconAlignCenter,
  IconAlignLeft,
  IconAlignRight,
  IconLayoutAlignLeftFilled,
  IconLayoutAlignRight,
  IconLayoutDistributeHorizontalFilled,
  IconLayoutSidebarFilled,
  IconLayoutSidebarRightFilled,
  IconPhoto,
  IconRepeat,
} from '@tabler/icons-react';

import { updateStep } from '../editorStore';
import { useEditableStep } from '../hooks/useEditableStep';

const layoutIconRelation: Record<MediaLayout, ReactNode> = {
  content: <IconLayoutDistributeHorizontalFilled size={20} />,
  left: <IconLayoutAlignLeftFilled size={20} />,
  right: <IconLayoutAlignRight size={20} />,
  fullLeft: <IconLayoutSidebarFilled size={20} />,
  fullRight: <IconLayoutSidebarRightFilled size={20} />,
  background: <IconAlignBoxCenterMiddleFilled size={20} />,
};

const alignIconRelation: Record<Align, ReactNode> = {
  start: <IconAlignLeft size={20} />,
  center: <IconAlignCenter size={20} />,
  end: <IconAlignRight size={20} />,
};

export const StepOptions = () => {
  const editableStep = useEditableStep();

  if (!editableStep) return null;

  const handleAlignChange = (align: Align) => {
    updateStep(editableStep?.id, {
      theme: {
        align,
      },
    });
  };

  const handleMediaLayoutChange = (layout: MediaLayout) => {
    updateStep(editableStep?.id, {
      media: editableStep.media
        ? {
            ...editableStep.media,
            layout,
          }
        : null,
    });
  };

  const handleImageChange = (image: string) => {
    updateStep(editableStep?.id, {
      media: {
        brightness: DEFAULT_MEDIA_BRIGHTNESS,
        size: DEFAULT_MEDIA_SIZE,
        layout: 'content',
        ...editableStep.media,
        image,
      },
    });
  };

  const handleBrightnessChange = (brightness: number) => {
    updateStep(editableStep?.id, {
      media: editableStep.media
        ? {
            ...editableStep.media,
            brightness,
          }
        : null,
    });
  };

  const handleBrightnessReset = () => {
    updateStep(editableStep?.id, {
      media: editableStep.media
        ? {
            ...editableStep.media,
            brightness: DEFAULT_MEDIA_BRIGHTNESS,
          }
        : null,
    });
  };

  const handleSizeChange = (size: number) => {
    updateStep(editableStep?.id, {
      media: editableStep.media
        ? {
            ...editableStep.media,
            size,
          }
        : null,
    });
  };

  const handleSizeReset = () => {
    updateStep(editableStep?.id, {
      media: editableStep.media
        ? {
            ...editableStep.media,
            size: DEFAULT_MEDIA_SIZE,
          }
        : null,
    });
  };

  const handleImageRemove = () => {
    updateStep(editableStep?.id, {
      media: null,
    });
  };

  const { theme, media } = editableStep;

  const align = theme?.align || 'start';

  const handleTitleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    updateStep(editableStep.id, {
      title: e.currentTarget.value,
    });
  };

  const handleDescriptionChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    updateStep(editableStep.id, {
      description: e.currentTarget.value,
    });
  };

  return (
    <Stack gap={24}>
      <TextInput
        label={'Заголовок'}
        placeholder="Заголовок"
        variant="default"
        value={editableStep.title}
        onChange={handleTitleChange}
      />

      <TextInput
        label={'Описание'}
        placeholder="Описание"
        variant="default"
        value={editableStep.description}
        onChange={handleDescriptionChange}
      />

      <Stack gap={8}>
        <InputLabel>Выравнивание</InputLabel>
        <ActionIcon.Group>
          {alignList.map((a) => (
            <ActionIcon
              key={a}
              variant={align === a ? 'filled' : 'default'}
              size="lg"
              onClick={() => handleAlignChange(a)}
            >
              {alignIconRelation[a]}
            </ActionIcon>
          ))}
        </ActionIcon.Group>
      </Stack>

      <Stack gap={8} align="flex-start">
        <InputLabel>Изображение</InputLabel>
        <CoverPopover
          hasImage={!!media}
          onChange={handleImageChange}
          onRemove={handleImageRemove}
          showTabs={['upload', 'link', 'unsplash', 'giphy']}
          position="right-start"
        >
          <ActionIcon size={48} variant="default">
            <Avatar src={media?.image} radius={'sm'}>
              <IconPhoto size={32} />
            </Avatar>
          </ActionIcon>
        </CoverPopover>
      </Stack>

      {media && (
        <>
          <Stack gap={8}>
            <InputLabel>Макет</InputLabel>
            <ActionIcon.Group>
              {mediaLayoutList.map((l) => (
                <ActionIcon
                  key={l}
                  variant={media?.layout === l ? 'filled' : 'default'}
                  size="lg"
                  onClick={() => handleMediaLayoutChange(l)}
                >
                  {layoutIconRelation[l]}
                </ActionIcon>
              ))}
            </ActionIcon.Group>
          </Stack>
          <Stack gap={8}>
            <Flex justify="space-between">
              <InputLabel>Яркость</InputLabel>
              <Tooltip label="Сбросить">
                <ActionIcon
                  size="xs"
                  variant="subtle"
                  onClick={handleBrightnessReset}
                >
                  <IconRepeat size={12} />
                </ActionIcon>
              </Tooltip>
            </Flex>
            <Slider
              min={0}
              max={200}
              step={1}
              label={null}
              value={media.brightness}
              onChange={handleBrightnessChange}
            />
          </Stack>
          {sizeableMediaLayouts.includes(media.layout) && (
            <Stack gap={8}>
              <Flex justify="space-between">
                <InputLabel>Размер</InputLabel>
                <Tooltip label="Сбросить">
                  <ActionIcon
                    size="xs"
                    variant="subtle"
                    onClick={handleSizeReset}
                  >
                    <IconRepeat size={12} />
                  </ActionIcon>
                </Tooltip>
              </Flex>
              <Slider
                min={1}
                max={100}
                step={1}
                label={null}
                value={media.size}
                onChange={handleSizeChange}
              />
            </Stack>
          )}
        </>
      )}

      {editableStep.isStart && (
        <TextInput
          label="Кнопка"
          placeholder="Начать"
          variant="default"
          value={editableStep.confirmLabel}
          onChange={(e) => {
            updateStep(editableStep.id, {
              confirmLabel: e.currentTarget.value,
            });
          }}
        />
      )}
    </Stack>
  );
};
