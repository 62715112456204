import { PropsWithChildren } from 'react';
import { Provider as ReduxProvider } from 'react-redux';

import { theme } from '@/config/theme';
import { MantineProvider } from '@mantine/core';
import { DatesProvider } from '@mantine/dates';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';

import { useDateFnsLocale } from './lang/useDateFnsLocale';
import { store } from './store';

import '@mantine/carousel/styles.css';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/tiptap/styles.css';

export const AppProviders = ({ children }: PropsWithChildren) => {
  useDateFnsLocale();

  return (
    <ReduxProvider store={store}>
      <MantineProvider
        theme={theme}
        defaultColorScheme="light"
        withCssVariables
      >
        <DatesProvider
          settings={{
            locale: 'ru',
            firstDayOfWeek: 1,
            weekendDays: [0, 6],
          }}
        >
          <ModalsProvider>{children}</ModalsProvider>
          <Notifications position="bottom-right" />
        </DatesProvider>
      </MantineProvider>
    </ReduxProvider>
  );
};
