export const deepFilter = <T extends { id: string | number; children: T[] }>(
  data: T[],
  pageId: string | number | undefined,
): T[] => {
  const filterRecursive = (items: T[]): T[] => {
    return items
      .filter((item) => item.id !== pageId)
      .map((item) => ({
        ...item,
        children: filterRecursive(item.children),
      }));
  };

  return filterRecursive(data);
};
