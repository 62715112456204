import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ApiPages, workspacesApi } from '@/api/workspaces';
import { store, useAppSelector } from '@/app/store';
import { ActionIcon, Button, Flex, Modal } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import {
  IconArrowsDiagonal2,
  IconArrowsDiagonalMinimize,
  IconX,
} from '@tabler/icons-react';
import { t } from 'i18next';
import { produce } from 'immer';

import { PageOptions } from '../Header/PageOptions';
import { PageBody } from '../PageBody/PageBody';
import { createPageModalSlice } from './slice';

import styles from './CreatePageModal.module.css';

export type BasePageData = {
  id: string;
  title: string;
  icon: string | null;
  coverPicture: ApiPages.CoverPicture;
  content: { html: string; json?: Record<string | number, any> };
  pageSettings: ApiPages.PageSettings;
  workspaceId: string;
  parentId: string | null;
  deleted?: boolean;
  public?: boolean;
  includeChildren?: boolean;
  deletedAt?: string | null;
  iscrypted?: boolean;
};

const initValues: BasePageData = {
  id: 'new_page',
  content: { html: '' },
  title: '',
  coverPicture: {
    url: null,
    verticalPosition: null,
  },
  icon: null,
  pageSettings: {
    font: 'san-serif',
    smallText: false,
    fullWidth: false,
    lock: false,
  },
  workspaceId: '',
  parentId: null,
};

const mergeValues = (values?: Partial<BasePageData>): BasePageData => {
  return {
    id: values?.id || initValues.id,
    content: values?.content || initValues?.content,
    title: values?.title || initValues.title,
    coverPicture: {
      url: values?.coverPicture?.url || initValues.coverPicture.url,
      verticalPosition:
        values?.coverPicture?.verticalPosition ||
        initValues.coverPicture.verticalPosition,
    },
    icon: values?.icon || initValues.icon,
    pageSettings: {
      font: values?.pageSettings?.font || initValues.pageSettings.font,
      smallText:
        values?.pageSettings?.smallText || initValues.pageSettings.smallText,
      fullWidth:
        values?.pageSettings?.fullWidth || initValues.pageSettings.fullWidth,
      lock: values?.pageSettings?.lock || initValues.pageSettings.lock,
    },
    workspaceId: values?.workspaceId || initValues.workspaceId,
    parentId: values?.parentId || initValues.parentId,
  };
};

export const closeCreatePageModal = () =>
  store.dispatch(createPageModalSlice.actions.close());
export const openCreatePageModal = (payload?: Partial<BasePageData>) =>
  store.dispatch(createPageModalSlice.actions.open(payload));

const Content = ({
  fullScreen,
  onFullSizeChange,
}: {
  fullScreen: boolean;
  onFullSizeChange: () => void;
}) => {
  const defaultValues = useAppSelector((s) => s.createPageModal.defaultValues);
  const navigate = useNavigate();
  const [createPage] = workspacesApi.endpoints.createPage.useMutation();

  const [page, setPage] = useState<BasePageData>(mergeValues(defaultValues));

  const handleSettings = useCallback((s: ApiPages.PageSettings) => {
    setPage((prev) =>
      produce(prev, (draft) => {
        draft.pageSettings.fullWidth = s.fullWidth;
        draft.pageSettings.smallText = s.smallText;
        draft.pageSettings.font = s.font;
        draft.pageSettings.lock = s.lock;
      }),
    );
  }, []);

  const handleIcon = useCallback((icon: string) => {
    setPage((prev) =>
      produce(prev, (draft) => {
        draft.icon = icon;
      }),
    );
  }, []);

  const handleCover = useCallback((cover: ApiPages.CoverPicture | null) => {
    setPage((prev) =>
      produce(prev, (draft) => {
        draft.coverPicture = cover || { url: '', verticalPosition: 0 };
      }),
    );
  }, []);

  const handleTitle = useCallback((title: string) => {
    setPage((prev) =>
      produce(prev, (draft) => {
        draft.title = title;
      }),
    );
  }, []);

  const handleContent = useCallback((content: string) => {
    setPage((prev) =>
      produce(prev, (draft) => {
        draft.content = { html: content };
      }),
    );
  }, []);

  const isMobile = useMediaQuery('(max-width: 992px)');

  const handleCreate = async () => {
    try {
      const payload = {
        title: page.title,
        icon: page.icon,
        coverPicture: page.coverPicture,
        content: page.content,
        pageSettings: page.pageSettings,
        workspaceId: page.workspaceId,
        parentId: page.parentId,
      };

      const newPage = await createPage(payload).unwrap();

      closeCreatePageModal();
      navigate(`/pages/${newPage.id}`);
    } catch (error) {
      console.error('Failed to create page:', error);
    }
  };

  return (
    <div style={fullScreen ? { height: '100%' } : {}}>
      <Flex
        justify={'space-between'}
        w={'100%'}
        p={8}
        className={styles.header}
      >
        <div>
          {!isMobile && (
            <ActionIcon
              variant="subtle"
              size="lg"
              color="gray"
              onClick={onFullSizeChange}
            >
              {fullScreen ? (
                <IconArrowsDiagonalMinimize size={20} />
              ) : (
                <IconArrowsDiagonal2 size={20} />
              )}
            </ActionIcon>
          )}
        </div>
        <Flex>
          <Button variant="filled" onClick={handleCreate}>
            {t('save')}
          </Button>
          <Flex gap={0}>
            <PageOptions page={page} handleSettings={handleSettings} />
            <ActionIcon
              variant="subtle"
              size="lg"
              color="gray"
              onClick={closeCreatePageModal}
            >
              <IconX size={20} />
            </ActionIcon>
          </Flex>
        </Flex>
      </Flex>
      <div style={{ marginTop: '52px' }}>
        <PageBody
          page={page}
          handleIcon={handleIcon}
          handleTitle={handleTitle}
          handleCover={handleCover}
          handleContent={handleContent}
        />
      </div>

      <Flex justify={'flex-end'} p={16}></Flex>
    </div>
  );
};

export const CreatePageModal = () => {
  const opened = useAppSelector((s) => s.createPageModal.opened);

  const [fullScreen, { toggle }] = useDisclosure(false);

  const isMobile = useMediaQuery('(max-width: 992px)');

  const computedFullScreen = isMobile ? true : fullScreen;

  return (
    <Modal
      styles={{
        body: {
          padding: 0,
          height: computedFullScreen ? '100dvh' : '80dvh',
          display: 'flex',
          flexDirection: 'column',
          overflowX: 'hidden',
        },
      }}
      size={'60%'}
      opened={opened}
      withCloseButton={false}
      fullScreen={computedFullScreen}
      onClose={closeCreatePageModal}
    >
      <Content fullScreen={fullScreen} onFullSizeChange={toggle} />
    </Modal>
  );
};
