import { NoData } from '@/components/ui/NoData/NoData';
import { APP_NAME } from '@/config/constants';
import { openCreatePageModal } from '@/features/CreatePageModal/CreatePageModal';
import { useActiveWorkspace } from '@/hooks/useActiveWorkspace';
import { useAccess } from '@/hooks/useWsProvider';
import { Container } from '@mantine/core';
import { IconFile } from '@tabler/icons-react';

import classes from './AppPage.module.css';

export const AppPage = () => {
  const access = useAccess();
  const activeWs = useActiveWorkspace();

  return (
    <Container className={classes.root}>
      {access.pages.create ? (
        <NoData
          illustration="addData"
          title={`Добро пожаловать в ${APP_NAME}`}
          titleProps={{
            maw: 600,
          }}
          buttons={[
            {
              leftSection: <IconFile size={16} />,
              children: 'Создать страницу',
              onClick: () => {
                openCreatePageModal({
                  workspaceId: activeWs?.workspaceId || '',
                });
              },
            },
          ]}
        />
      ) : (
        <NoData
          title={`Добро пожаловать в ${APP_NAME}`}
          titleProps={{
            maw: 600,
          }}
          illustration="addData"
        />
      )}
    </Container>
  );
};
