import { useMemo } from 'react';

import { useAppSelector } from '@/app/store';
import { UserAvatar } from '@/components/ui/UserAvatar';
import { Avatar, Flex, Menu, Text, Tooltip } from '@mantine/core';
import { ActiveUserProfile } from '@sinups/editor-dsd';
import { xor } from 'lodash-es';

// import styles from './styles.module.css';

const MAX_SHOW_COUNT = 3;
const initialUser = {
  activeWorkspaceId: '',
  name: '',
  email: '',
  profilePicture: { url: '' },
  workspaces: [],
  createdAt: new Date(),
  id: '',
  lang: '',
  plan: '',
  darkMode: false,
};

type SocketRoomUsersProps = {
  roomId: string;
  selected?: string[];
  onSelect?: (v: string[]) => void;
};

export const SocketRoomUsers = ({
  selected,
  onSelect,
}: SocketRoomUsersProps) => {
  const users = useAppSelector((s) => s.activeUsers.users);

  const { usersToShow, hiddenUsers } = useMemo(() => {
    const usersToShow = users.slice(0, MAX_SHOW_COUNT);
    const hiddenUsers = users.slice(MAX_SHOW_COUNT);

    return { usersToShow, hiddenUsers };
  }, [users]);

  return (
    <Tooltip.Group openDelay={300} closeDelay={100}>
      <Avatar.Group spacing="sm">
        {usersToShow.map((user: ActiveUserProfile) => {
          const isSelected = selected?.includes(user.id);
          return (
            <Tooltip key={user.id} label={user.name} withArrow>
              <UserAvatar
                size={32}
                onClick={() => onSelect?.(xor(selected, [user.id]))}
                user={{ ...initialUser, name: user.name, id: user.id }}
                style={{
                  boxShadow: isSelected
                    ? '0 0 0 2px var(--mantine-color-blue-5)'
                    : undefined,
                  cursor: 'pointer',
                  backgroundColor: user.color,
                  // borderColor: isConnected(user.id)
                  //   ? 'var(--mantine-color-green-5)'
                  //   : undefined,
                  // borderWidth: isConnected(user.id) ? 2 : undefined,
                }}
              />
            </Tooltip>
          );
        })}
        {hiddenUsers.length > 0 && (
          <Menu
            closeOnItemClick={false}
            transitionProps={{ transition: 'fade-down', duration: 150 }}
          >
            <Menu.Target>
              <Avatar size={32} radius="xl" color="gray">
                +{hiddenUsers.length}
              </Avatar>
            </Menu.Target>
            <Menu.Dropdown mah={300} style={{ overflow: 'auto' }}>
              {hiddenUsers.map((user: ActiveUserProfile) => {
                //   const isSelected = selected?.includes(user.id);

                return (
                  <Menu.Item key={user.id}>
                    <Flex>
                      <UserAvatar
                        size={36}
                        user={{ ...initialUser, name: user.name, id: user.id }}
                        style={{
                          cursor: 'default',
                          backgroundColor: user.color,
                        }}
                      />
                      <Text fw={400}> {user.name}</Text>
                    </Flex>
                    {/* <Checkbox
                      checked={isSelected}
                      classNames={{
                        root: styles.root,
                        labelWrapper: styles.labelWrapper,
                      }}
                      styles={{
                        body: {
                          display: 'flex',
                          alignItems: 'center',
                        },
                      }}
                      label={
                        <Flex>
                          <UserAvatar
                            size={36}
                            user={user}
                            style={{ cursor: 'default' }}
                          />
                          <Text fw={400}> {user.name}</Text>
                        </Flex>
                      }
                      onChange={() => onSelect?.(xor(selected, [user.id]))}
                    /> */}
                  </Menu.Item>
                );
              })}
            </Menu.Dropdown>
          </Menu>
        )}
      </Avatar.Group>
    </Tooltip.Group>
  );
};
