import { createSlice } from '@reduxjs/toolkit';

interface ActiveUsersState {
  users: any;
}

const initialState: ActiveUsersState = {
  users: [],
};

export const activeUsersSlice = createSlice({
  name: 'activeUsers',
  initialState,
  reducers: {
    setActiveUsers(state, action) {
      state.users = action.payload;
    },
  },
});
