import { createApi } from '@reduxjs/toolkit/query/react';

import { customBaseQuery } from '../customBaseQuery';
import { ApiFavorites } from './types';

export const favoritesApi = createApi({
  reducerPath: 'favoritesApi',
  baseQuery: customBaseQuery(),
  tagTypes: ['Favorites'],
  endpoints: (builder) => ({
    addFavorite: builder.mutation<void, string>({
      query: (pageId) => ({
        url: '/v1/favorites',
        method: 'POST',
        body: {
          pageId,
        },
      }),
      invalidatesTags: [{ type: 'Favorites', id: 'LIST' }],
    }),

    deleteFavorite: builder.mutation<void, string>({
      query: (pageId) => ({
        url: `/v1/favorites/${pageId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Favorites', id: 'LIST' }],
      onQueryStarted: async (
        pageId,
        { dispatch, queryFulfilled, getState },
      ) => {
        const params = favoritesApi.util.selectCachedArgsForQuery(
          getState(),
          'getFavorites',
        );

        const patchResults = params.map((el) => {
          return dispatch(
            favoritesApi.util.updateQueryData('getFavorites', el, (draft) => {
              draft.splice(
                draft.findIndex((p) => p.id !== pageId),
                1,
              );
            }),
          );
        });

        try {
          await queryFulfilled;
        } catch {
          patchResults.forEach((el) => el.undo());
        }
      },
    }),
    getFavorites: builder.query<ApiFavorites.GetFavoritesResponse, void>({
      query: () => '/v1/pages/favorites',
      providesTags: [{ type: 'Favorites', id: 'LIST' }],
    }),
  }),
});
