import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { ApiUsers, usersApi } from '@/api/users';
import { store } from '@/app/store';
import { WorkspaceAvatar } from '@/components/ui/WorkspaceAvatar/WorkspaceAvatar';
import { AppRoutes } from '@/config/routes';
import { createWorkspaceModalSlice } from '@/features/slice';
import { useActiveWorkspace } from '@/hooks/useActiveWorkspace';
import { useCurrentUserData } from '@/hooks/useCurrentUserData';
import { isGuest, useAccess } from '@/hooks/useWsProvider';
import { Button, Flex, Group, Menu, Text, Tooltip } from '@mantine/core';
import {
  IconCheck,
  IconCirclePlus,
  IconSelector,
  IconSettings,
  IconUsersPlus,
} from '@tabler/icons-react';

import { layoutSlice } from '../AppLayout/slice';
import { useMenu } from '../hooks/useMenu';

import styles from './WorkspaceMenu.module.css';

export const WorkspaceMenu = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const data = useCurrentUserData();
  const access = useAccess();
  const [patch] = usersApi.endpoints.patchUser.useMutation();
  const { close, isMobile } = useMenu();
  const activeWs = useActiveWorkspace();
  const activeWorkspaceId = activeWs?.workspaceId;

  const handleWorkspaceClick = (w: ApiUsers.Workspace) => {
    store.dispatch(layoutSlice.actions.setActiveWorkspaceId(w.workspaceId));

    if (w.pages.length) {
      navigate('/');
    }

    patch({
      id: data.id,
      activeWorkspaceId: w.workspaceId,
    });
  };

  const handleCreateWorkspace = () => {
    if (isMobile) {
      close();
    }
    store.dispatch(createWorkspaceModalSlice.actions.open());
  };

  return (
    <>
      <Menu
        shadow="xs"
        position="bottom-end"
        keepMounted={false}
        transitionProps={{ transition: 'pop-top-left', duration: 150 }}
      >
        <Menu.Target>
          <Flex className={styles.userMenu} miw={0} w={'100%'}>
            <WorkspaceAvatar size={30} workspace={activeWs} />

            <Flex justify={'space-between'} align="center" w={'100%'} miw={0}>
              <Tooltip label={activeWs?.workspaceName || 'Empty'}>
                <Text size="md" truncate>
                  {activeWs?.workspaceName || 'Empty'}
                </Text>
              </Tooltip>
              <IconSelector size={16} color="gray" />
            </Flex>
          </Flex>
        </Menu.Target>
        <Menu.Dropdown>
          <Group justify="flex-start" px={12} py={6} w={'100%'}>
            <Link to={AppRoutes.settings}>
              <Button
                leftSection={<IconSettings size={16} />}
                variant="default"
              >
                {t('settings')}
              </Button>
            </Link>

            <Link to={AppRoutes.settingsMembers}>
              <Button
                variant="default"
                disabled={isGuest(access.role)}
                leftSection={<IconUsersPlus size={16} />}
              >
                {t('members')}
              </Button>
            </Link>
          </Group>
          <Menu.Divider />
          <Menu.Label fw={400}>Пространство</Menu.Label>
          <div
            style={{
              overflowY: 'auto',
              overflowX: 'hidden',
              maxHeight: 217,
            }}
          >
            {data.workspaces.map((w) => (
              <Menu.Item
                onClick={() => handleWorkspaceClick(w)}
                leftSection={<WorkspaceAvatar size={30} workspace={w} />}
                key={w.workspaceId}
                rightSection={
                  activeWorkspaceId === w.workspaceId ? (
                    <IconCheck size={16} />
                  ) : null
                }
              >
                {w.workspaceName}
              </Menu.Item>
            ))}
          </div>
          <Menu.Divider />
          <Menu.Item
            onClick={handleCreateWorkspace}
            leftSection={<IconCirclePlus stroke={1.5} size={20} />}
          >
            Добавить пространство
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </>
  );
};
