import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ButtonProps,
  PolymorphicComponentProps,
  Stack,
} from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';

import { openSearchModal } from '../../../../../features/SearchModal/SearchModal';

export const MenuItems = () => {
  const { t } = useTranslation();

  const items = useMemo<PolymorphicComponentProps<'button', ButtonProps>[]>(
    () => [
      {
        leftSection: <IconSearch size={16} />,
        children: t('search'),
        name: 'search',
        onClick: openSearchModal,
      },
    ],
    [t],
  );

  return (
    <>
      <Stack p={16} gap={4}>
        {items.map((button) => (
          <Button
            variant="subtle"
            key={button.name}
            color="dark"
            justify="flex-start"
            {...button}
          />
        ))}
      </Stack>
    </>
  );
};
