import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import queryString from 'query-string';

import { ApiUnsplash } from './types';

const CLIENT_ID = import.meta.env.VITE_UNSPLASH;

type Data = {
  items: ApiUnsplash.Datum[];
  page: number;
  totalPages: number;
  hasMore: boolean;
};

export const unsplashApi = createApi({
  reducerPath: 'unsplashApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.unsplash.com',
    prepareHeaders: async (headers) => {
      headers.set('Authorization', `Client-ID ${CLIENT_ID}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPhotos: builder.query<Data, ApiUnsplash.Request>({
      query: (params) => {
        const url = params.query ? '/search/photos' : '/photos';
        return {
          url: `${url}?${queryString.stringify(params)}`,
          method: 'GET',
        };
      },
      serializeQueryArgs: ({ endpointName }) => endpointName,
      transformResponse: (res: ApiUnsplash.Response, _, arg) => {
        const items = res && 'results' in res ? res.results : res;
        const page = arg.page || 1;
        const totalPages = res && 'total_pages' in res ? res.total_pages : 999;

        const data: Data = {
          items,
          page,
          totalPages,
          hasMore: totalPages > page,
        };

        return data;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      merge: (cache, res) => {
        if (res.page > 1) {
          return {
            ...res,
            items: [...cache.items, ...res.items],
          };
        }

        return res;
      },
    }),
  }),
});
