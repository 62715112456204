import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiPages } from '@/api/workspaces';
import { useMenu } from '@/components/layout/hooks/useMenu';
import { SHOW_GIPHY, SHOW_UNSPLASH } from '@/config/constants';
import { useDragController } from '@/hooks/useDragController';
import { usePageState } from '@/hooks/usePageState';
import { Badge, Button, ButtonGroup, Flex } from '@mantine/core';
import { IconFocusCentered } from '@tabler/icons-react';
import { clamp } from 'lodash-es';

import { BasePageData } from '../CreatePageModal/CreatePageModal';
import { CoverPopover } from './CoverPopover';

type PopoverTab = 'unsplash' | 'giphy' | 'link' | 'gallery' | 'upload';

export const PageCover = <
  PAGE extends Pick<
    BasePageData,
    'id' | 'coverPicture' | 'pageSettings' | 'deleted'
  >,
>({
  page,
  handleCover,
}: {
  page: PAGE;
  handleCover?: (v: ApiPages.CoverPicture | null) => void;
}) => {
  const { t } = useTranslation();
  const { isMobile } = useMenu();
  const { canEdit } = usePageState(page);

  const [position, setPosition] = useState<number>(0);
  const [reposition, setReposition] = useState<boolean>(false);

  const popoverTabs: PopoverTab[] = [
    'unsplash',
    'giphy',
    'link',
    'gallery',
    'upload',
  ].filter(
    (tab): tab is PopoverTab =>
      (tab === 'unsplash' && SHOW_UNSPLASH) ||
      (tab === 'giphy' && SHOW_GIPHY) ||
      (tab !== 'unsplash' && tab !== 'giphy'),
  );

  useEffect(() => {
    if (!canEdit) {
      setReposition(false);
    }
  }, [canEdit]);

  const { listeners } = useDragController({
    onMove: ({ movementY }) => {
      setPosition((prev) => clamp(Math.round(prev - movementY), -50, 50));
    },
  });

  const handleSave = () => {
    setReposition(false);
    handleCover?.({
      url: page.coverPicture.url,
      verticalPosition: position,
    });
  };

  const handleStartReposition = () => {
    setReposition(true);
    setPosition(page.coverPicture.verticalPosition || 0);
  };

  const handleChange = (url: string): void | undefined => {
    handleCover?.({
      url,
      verticalPosition: 0,
    });
  };

  return (
    <Flex
      h={180}
      pos={'relative'}
      align={'flex-end'}
      justify={'flex-end'}
      p={16}
      {...listeners}
      style={{
        backgroundSize: 'cover',
        backgroundPosition: `center ${reposition ? position + 50 : (page.coverPicture.verticalPosition || 0) + 50}%`,
        backgroundImage: `url(${page.coverPicture.url})`,
        backgroundRepeat: 'no-repeat',
        cursor: reposition ? 'grab' : undefined,
      }}
    >
      {reposition ? (
        <>
          <Badge
            variant="light"
            color="white"
            tt={'none'}
            pos={'absolute'}
            top={'50%'}
            left={'50%'}
            w={isMobile ? '90%' : 'auto'}
            fz={14}
            p={12}
            fw={500}
            bg={'rgba(0,0,0,0.5)'}
            style={{
              userSelect: 'none',
              transform: 'translate(-50%, -50%)',
            }}
          >
            {t('changeCover.dragTextDescription')}
          </Badge>
          <ButtonGroup>
            <Button
              variant="default"
              size="xs"
              onClick={() => setReposition(false)}
            >
              {t('changeCover.dragCancel')}
            </Button>
            <Button variant="default" size="xs" onClick={handleSave}>
              {t('changeCover.dragSave')}
            </Button>
          </ButtonGroup>
        </>
      ) : canEdit ? (
        <Flex justify={'flex-end'}>
          <CoverPopover
            hasImage={!!page.coverPicture.url}
            onChange={handleChange}
            showTabs={popoverTabs}
            onRemove={() => handleCover?.(null)}
          />
          <Button
            onClick={handleStartReposition}
            variant="default"
            size="xs"
            leftSection={<IconFocusCentered size={16} />}
          >
            {t('changeCover.position')}
          </Button>
        </Flex>
      ) : null}
    </Flex>
  );
};
