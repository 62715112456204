import { ChangeEventHandler, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { giphyApi } from '@/api/giphy';
import { NoData } from '@/components/ui/NoData/NoData';
import {
  AspectRatio,
  Box,
  Button,
  Loader,
  SimpleGrid,
  Stack,
  TextInput,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { IconSearch } from '@tabler/icons-react';

import styles from './ItemImage.module.css';

export const CoverPopoverGiphy = ({
  onChange,
}: {
  onChange?: (v: string) => void;
}) => {
  const { t } = useTranslation();

  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 800);
  const [page, setPage] = useState<number>(1);

  const { data, isLoading } = giphyApi.endpoints.getPhotos.useQuery({
    q: debouncedQuery,
    limit: 12,
    offset: page * 12,
  });

  const handleSearch = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      setQuery(e.currentTarget.value);
      setPage(1);
    },
    [],
  );

  return (
    <Stack gap={16}>
      <TextInput
        leftSection={
          isLoading ? <Loader size={16} /> : <IconSearch size={16} />
        }
        maxLength={50}
        placeholder={t('search')}
        data-autofocus
        value={query}
        onChange={handleSearch}
      />

      <Box mah={400} w="100%" style={{ overflow: 'auto' }}>
        {data?.items.length ? (
          <Stack gap={8}>
            <SimpleGrid cols={4} spacing={8}>
              {data.items.map((el) => (
                <AspectRatio
                  key={el.id}
                  onClick={() => onChange?.(el.images.original.url)}
                  ratio={120 / 65}
                  className={styles.itemImage}
                  style={{
                    backgroundImage: `url(${el.images.preview_webp.url})`,
                  }}
                />
              ))}
            </SimpleGrid>
            {data.hasMore && (
              <Button
                variant="subtle"
                loading={isLoading}
                onClick={() => setPage((prev) => prev + 1)}
              >
                Загрузить ещё
              </Button>
            )}
          </Stack>
        ) : (
          <Box pb={16}>
            <NoData
              size={120}
              illustration="noImage"
              description={'Нет изображений'}
              titleProps={{ size: 'xs', c: 'dimmed' }}
            />
          </Box>
        )}
      </Box>
    </Stack>
  );
};
