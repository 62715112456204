import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { authApi } from '@/api/auth';
import { favoritesApi } from '@/api/favorites/slice';
import { filesApi } from '@/api/files';
import { formsApi } from '@/api/forms';
import { giphyApi } from '@/api/giphy';
import { settingsApi } from '@/api/settings';
import { unsplashApi } from '@/api/unsplash';
import { usersApi } from '@/api/users/slice';
import { workspacesApi } from '@/api/workspaces';
import { layoutSlice } from '@/components/layout/AppLayout/slice';
import { createFormModalSlice } from '@/features/CreateFormModal/slice';
import { createPageModalSlice } from '@/features/CreatePageModal/slice';
import { activeUsersSlice } from '@/features/Editor/slice';
import { searchModalSlice } from '@/features/SearchModal/slice';
import { createWorkspaceModalSlice } from '@/features/slice';
import { formPageSlice } from '@/pages/FormPage/slice';
import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';

export const store = configureStore({
  reducer: {
    // ENDPOINTS
    [usersApi.reducerPath]: usersApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [workspacesApi.reducerPath]: workspacesApi.reducer,
    [filesApi.reducerPath]: filesApi.reducer,
    [favoritesApi.reducerPath]: favoritesApi.reducer,
    [formsApi.reducerPath]: formsApi.reducer,
    [unsplashApi.reducerPath]: unsplashApi.reducer,
    [giphyApi.reducerPath]: giphyApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
    // OTHER
    [layoutSlice.reducerPath]: layoutSlice.reducer,
    [createWorkspaceModalSlice.reducerPath]: createWorkspaceModalSlice.reducer,
    [createPageModalSlice.reducerPath]: createPageModalSlice.reducer,
    [createFormModalSlice.reducerPath]: createFormModalSlice.reducer,
    [formPageSlice.reducerPath]: formPageSlice.reducer,
    [searchModalSlice.reducerPath]: searchModalSlice.reducer,
    [activeUsersSlice.reducerPath]: activeUsersSlice.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['workspacesApi/executeMutation/fulfilled'],
        ignoredPaths: ['workspacesApi.mutations'],
      },
    })
      .concat(usersApi.middleware)
      .concat(authApi.middleware)
      .concat(workspacesApi.middleware)
      .concat(formsApi.middleware)
      .concat(filesApi.middleware)
      .concat(favoritesApi.middleware)
      .concat(unsplashApi.middleware)
      .concat(giphyApi.middleware)
      .concat(settingsApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
