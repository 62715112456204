import { useRef } from 'react';
import { Navigate } from 'react-router-dom';

import { useMenu } from '@/components/layout/hooks/useMenu';
import { SettingsCard } from '@/components/ui/SettingCard';
import { WorkspaceAvatar } from '@/components/ui/WorkspaceAvatar/WorkspaceAvatar';
import { CoverPopover } from '@/features/PageCover/CoverPopover';
import { useActiveWorkspace } from '@/hooks/useActiveWorkspace';
import useWorkspaceForm from '@/hooks/useWorkspaceForm';
import { isOwner, useAccess } from '@/hooks/useWsProvider';
import {
  ActionIcon,
  Box,
  Button,
  Card,
  Container,
  Group,
  InputLabel,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';

export const WorkspaceSettings = () => {
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const { form, handleSubmit, handleRemove, isSaved, isSubmitting } =
    useWorkspaceForm();
  const { isMobile } = useMenu();
  const activeWs = useActiveWorkspace();

  const access = useAccess();

  const handleExternalSubmit = () => {
    if (submitButtonRef.current) {
      submitButtonRef.current.click();
    }
  };

  if (!access.workspace.edit) {
    return <Navigate to="/" replace />;
  }

  return (
    <Container p={isMobile ? 0 : 16}>
      <Card
        p={isMobile ? 10 : 16}
        mx="sm"
        padding="lg"
        style={{ margin: 'auto' }}
      >
        <SettingsCard
          title="Настройки рабочего пространства"
          description="Управляйте настройками вашего рабочего пространства"
          isSwitchEnabled={false}
          defaultContentVisible={true}
          buttons={[
            <Button
              size="sm"
              variant="filled"
              type="button"
              disabled={isSaved || isSubmitting || !form.isDirty()}
              onClick={handleExternalSubmit}
            >
              Обновить
            </Button>,
          ]}
        >
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <Stack gap={16} align="flex-start" id="hui">
              <Group wrap="nowrap" justify={'flex-start'} w={'100%'}>
                <CoverPopover
                  onChange={(v) => form.setFieldValue('avatarUrl', v)}
                  onRemove={() => form.setFieldValue('avatarUrl', '')}
                  showTabs={['upload', 'link']}
                  position="bottom-start"
                >
                  <ActionIcon size={65} variant="light">
                    <WorkspaceAvatar
                      size={60}
                      workspace={{
                        workspaceId: activeWs?.workspaceId || '',
                        avatarUrl: form.values.avatarUrl,
                        workspaceName: form.values.name,
                      }}
                    />
                  </ActionIcon>
                </CoverPopover>
                <Box w={'50%'}>
                  <InputLabel>Название</InputLabel>
                  <TextInput
                    mt={'4px'}
                    placeholder="Название пространства"
                    {...form.getInputProps('name')}
                  />
                </Box>
              </Group>

              <button
                type="submit"
                ref={submitButtonRef}
                style={{ display: 'none' }}
              >
                Hidden Submit
              </button>
            </Stack>
          </form>
        </SettingsCard>

        {isOwner(access.role) && (
          <SettingsCard
            title="Удалить рабочее пространство"
            isSwitchEnabled={false}
            defaultContentVisible={true}
            buttons={[
              <Button
                radius="md"
                size="sm"
                variant="filled"
                color="red"
                onClick={handleRemove}
              >
                Удалить пространство
              </Button>,
            ]}
          >
            <Stack gap={16} align="flex-start" my="lg">
              <Text c="dimmed">
                Удалить рабочее пространство можно в любое время, но вы не
                сможете восстановить данные.
              </Text>
            </Stack>
          </SettingsCard>
        )}
      </Card>
    </Container>
  );
};
