import { ChangeEventHandler, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { showRequestNotifications } from '@/api/helpers/showNotifications';
import { workspacesApi } from '@/api/workspaces';
import { SHOW_CUSTOM_UI } from '@/config/constants';
import { AppRoutes } from '@/config/routes';
import { generateLink } from '@/helpers/generateLink';
import { ComplexHierarchyPageData, usePagesTree } from '@/hooks/usePagesTree';
import {
  ActionIcon,
  Button,
  Center,
  CopyButton,
  Divider,
  Flex,
  FocusTrap,
  Modal,
  PinInput,
  Popover,
  Stack,
  Switch,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import {
  IconArrowRight,
  IconCheck,
  IconCopy,
  IconFile,
  IconPaint,
  IconShare,
} from '@tabler/icons-react';

import { BasePageData } from '../CreatePageModal/CreatePageModal';

export const SharePanel = <
  PAGE extends Pick<
    BasePageData,
    'id' | 'public' | 'includeChildren' | 'parentId' | 'iscrypted'
  >,
>({
  page,
}: {
  page: PAGE;
}) => {
  const { t } = useTranslation();
  const [opened, { open, close, toggle }] = useDisclosure();
  const [modalOpen, setModalOpen] = useState(false);
  const [pin, setPin] = useState('');

  const isMobile = useMediaQuery('(max-width: 768px)');
  const { complexData } = usePagesTree(page.id);

  const childrenIds = useMemo(() => {
    const ids: string[] = [];

    const addIds = (els: ComplexHierarchyPageData[]) => {
      els.forEach((el) => {
        ids.push(el.id);
        addIds(el.children);
      });
    };

    addIds(complexData);

    return ids;
  }, [complexData]);

  const link =
    window.location.origin + generateLink(AppRoutes.share, { pageId: page.id });

  const [publish] = workspacesApi.endpoints.publishPage.useMutation();
  const [update] = workspacesApi.endpoints.updatePage.useMutation();
  const [lock, { isLoading: lockLoading }] =
    workspacesApi.endpoints.lockPage.useMutation();

  const handlePublish: ChangeEventHandler<HTMLInputElement> = (e) => {
    showRequestNotifications(
      publish({
        pageId: page.id,
        isPublic: e.currentTarget.checked,
        includeChildren: !!page.includeChildren,
        childrenIds: childrenIds,
      }).unwrap(),
      {
        errorMsg: 'Ошибка',
      },
    );
  };

  const handleLockChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.currentTarget.checked) {
      close();
      setPin('');
      setModalOpen(true);
    } else {
      update({
        id: page.id,
        iscrypted: false,
      });
    }
  };

  const handlePinChange = () => {
    close();
    setPin('');
    setModalOpen(true);
  };

  const handleChildrenPublish: ChangeEventHandler<HTMLInputElement> = (e) => {
    publish({
      pageId: page.id,
      isPublic: !!page.public,
      includeChildren: e.currentTarget.checked,
      childrenIds,
    });
  };

  const handleView = () => {
    window.open(link, '_blank')?.focus();
    close();
  };

  const publicByParent = false;

  const handlePinComplete = async () => {
    lock({
      pageId: page.id,
      pin: pin,
    }).then(() => {
      setModalOpen(false);
    });
  };

  return (
    <>
      <Popover
        position="bottom-end"
        opened={opened}
        onClose={close}
        onOpen={open}
      >
        <Popover.Target>
          {isMobile ? (
            <ActionIcon
              variant="subtle"
              size="lg"
              onClick={toggle}
              color="gray"
            >
              <IconShare size={20} />
            </ActionIcon>
          ) : (
            <Button onClick={toggle} variant="subtle" color="gray">
              {t('share')}
            </Button>
          )}
        </Popover.Target>
        <Popover.Dropdown>
          <Text fw={600} size="lg" mb={24}>
            {t('share')}
          </Text>

          <Stack gap={24} maw={400}>
            <Stack gap={8}>
              <Switch
                disabled={!!publicByParent}
                label="Опубликовать в Интернете"
                checked={page.public}
                onChange={handlePublish}
              />
              <Text size="sm" c="dimmed">
                {page.public ? (
                  publicByParent ? (
                    <>
                      <ParentLinkButton parentId={page.parentId} />
                      {t('sharePanel.haveAccessByParent')}
                    </>
                  ) : (
                    t('sharePanel.everyoneHaveAccess')
                  )
                ) : (
                  t('sharePanel.everyoneWillHaveAccess')
                )}
              </Text>
              {page.public && (
                <>
                  <Flex>
                    <TextInput
                      flex={1}
                      value={link}
                      readOnly
                      rightSection={
                        <CopyButton value={link}>
                          {({ copied, copy }) => (
                            <Tooltip
                              label={
                                copied ? t('utils.linkCopied') : t('utils.copy')
                              }
                            >
                              <ActionIcon onClick={copy} variant="subtle">
                                {copied ? (
                                  <IconCheck size={16} />
                                ) : (
                                  <IconCopy size={16} />
                                )}
                              </ActionIcon>
                            </Tooltip>
                          )}
                        </CopyButton>
                      }
                    />
                    <Button size="sm" onClick={handleView}>
                      Перейти
                    </Button>
                  </Flex>
                  <Switch
                    mt={16}
                    disabled={!!publicByParent}
                    label="Задать пароль"
                    checked={page.iscrypted}
                    onChange={handleLockChange}
                  />
                  {page.iscrypted && (
                    <>
                      <Text size="sm" c="dimmed">
                        Для доступа к документу будет необходимо ввести PIN-код.
                        Для вложенных документов пароль требоваться не будет.
                      </Text>
                      <div>
                        {' '}
                        <Button
                          variant="light"
                          color="gray.4"
                          c="gray.8"
                          size="xs"
                          onClick={handlePinChange}
                        >
                          Изменить PIN-код
                        </Button>
                      </div>
                    </>
                  )}
                </>
              )}
            </Stack>
            {page.public && !publicByParent && (
              <>
                <Switch
                  label="Поделиться вложенными документами"
                  onChange={handleChildrenPublish}
                  checked={page.includeChildren}
                />
                {SHOW_CUSTOM_UI && (
                  <>
                    <Divider />
                    <Link to={AppRoutes.settingsPublish}>
                      <Button
                        variant="subtle"
                        justify="flex-start"
                        fullWidth
                        leftSection={<IconPaint size={16} />}
                        size="xs"
                      >
                        Настройки внешнего вида
                      </Button>
                    </Link>
                  </>
                )}
              </>
            )}
          </Stack>
        </Popover.Dropdown>
      </Popover>
      <Modal
        opened={modalOpen}
        onClose={() => setModalOpen(false)}
        centered
        title={'Введите PIN-код'}
      >
        <Stack gap={24}>
          <Stack gap={4}>
            <Text c="dimmed">
              Введите PIN-код, который хотите использовать для доступа к
              документу
            </Text>
          </Stack>
          <FocusTrap active>
            <Center>
              <PinInput
                mask
                value={pin}
                onChange={setPin}
                type="number"
                onKeyDownCapture={(e) => {
                  if (e.key === ' ') {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
                onComplete={handlePinComplete}
                length={5}
              />
            </Center>
          </FocusTrap>
          <Button
            disabled={pin.length < 5}
            onClick={handlePinComplete}
            loading={lockLoading}
          >
            Продолжить
          </Button>
        </Stack>
      </Modal>
    </>
  );
};

const ParentLinkButton = ({ parentId }: { parentId: string | null }) => {
  const { pageEntities } = usePagesTree();

  const page = parentId ? pageEntities[parentId] : null;

  if (!page) return null;

  return (
    <Link
      to={generateLink(AppRoutes.pages, {
        pageId: page.parentId,
      })}
      onClick={close}
    >
      <Button
        justify="flex-start"
        my={8}
        w={'100%'}
        variant="outline"
        leftSection={page.icon || <IconFile size={16} />}
        rightSection={<IconArrowRight size={16} />}
      >
        <Text truncate miw={0}>
          {page.title || 'Без названия'}
        </Text>
      </Button>
    </Link>
  );
};
