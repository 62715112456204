import React, { forwardRef } from 'react';

import { Avatar, AvatarProps, Box } from '@mantine/core';
import { useAvatarColor } from '@sinups/agg';
import { IconBuilding } from '@tabler/icons-react';

type WorkspaceType = {
  workspaceId?: string;
  workspaceName: string;
  avatarUrl: string;
};

interface UserAvatarProps
  extends AvatarProps,
    Omit<React.ComponentPropsWithoutRef<'div'>, keyof AvatarProps> {
  workspace?: WorkspaceType;
}

export const WorkspaceAvatar = forwardRef<HTMLDivElement, UserAvatarProps>(
  ({ workspace, size = 24, radius = 'sm', ...props }, ref) => {
    const bg = useAvatarColor(workspace?.workspaceId ?? '');

    return (
      <Box fz={size}>
        <Avatar
          src={workspace?.avatarUrl}
          tt={'uppercase'}
          size={size}
          radius={radius}
          bg={bg}
          {...props}
          styles={{
            placeholder: {
              fontWeight: 600,
              fontSize: 'calc(1em * 0.6)',
              color: 'white',
            },
          }}
          ref={ref}
        >
          {!workspace?.avatarUrl &&
            (workspace?.workspaceName.trim()?.charAt(0) || (
              <IconBuilding size={'70%'} />
            ))}
        </Avatar>
      </Box>
    );
  },
);
