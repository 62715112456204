import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { showRequestNotifications } from '@/api/helpers/showNotifications';
import { workspacesApi } from '@/api/workspaces';
import { NoData } from '@/components/ui/NoData/NoData';
import { APP_NAME } from '@/config/constants';
import { AppRoutes } from '@/config/routes';
import { generateLink } from '@/helpers/generateLink';
import { useActiveWorkspace } from '@/hooks/useActiveWorkspace';
import { removePage } from '@/hooks/useRemove';
import {
  ActionIcon,
  Box,
  Container,
  Flex,
  FocusTrap,
  LoadingOverlay,
  Skeleton,
  Stack,
  Text,
  TextInput,
  Title,
  Tooltip,
} from '@mantine/core';
import { useDebouncedValue, useInputState } from '@mantine/hooks';
import {
  IconCornerUpLeft,
  IconFile,
  IconSearch,
  IconX,
} from '@tabler/icons-react';
import { formatDistanceToNow } from 'date-fns';

import styles from './TrashPage.module.css';

export const TrashPage = () => {
  const activeWs = useActiveWorkspace();

  const [value, setValue] = useInputState('');
  const [query] = useDebouncedValue(value, 800);
  const { t } = useTranslation();
  const { data, isLoading } = workspacesApi.endpoints.getTrash.useQuery({
    limit: 1000,
    offset: 0,
    workspaceId: activeWs?.workspaceId || '',
    query,
  });

  const [restore, { isLoading: isRestoreLoading }] =
    workspacesApi.endpoints.restorePage.useMutation();

  const handleRestore = async (id: string, title: string) => {
    try {
      const result = restore(id);
      showRequestNotifications(result, {
        successMsg: t('trash.pageRestored', { pageName: title }),
        errorMsg: t('trash.pageRestoreFailed', { pageName: title }),
      });
    } catch (error) {
      showRequestNotifications(Promise.reject(error), {
        notifyId: 'restore-file',
        errorMsg: t('trash.pageRestoreFailed', { pageName: title }),
      });
      console.error('Failed to update user:', error);
    }
  };

  if (isLoading) {
    return (
      <>
        <Helmet>
          <title>Корзина | {APP_NAME}</title>
        </Helmet>
        <Stack gap={8} px={16}>
          <Skeleton h={48} />
          <Skeleton h={48} />
          <Skeleton h={48} />
          <Skeleton h={48} />
        </Stack>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Корзина | {APP_NAME}</title>
      </Helmet>
      <Container>
        <Stack gap={12}>
          <div>
            <Title>Корзина</Title>
            <Text mb={12} c="dimmed">
              Документы в корзине хранятся 30 дней. По истечении этого срока они
              автоматически удаляются безвозратно.
            </Text>
          </div>
          <FocusTrap active>
            <TextInput
              value={value}
              onChange={setValue}
              leftSection={<IconSearch size={16} />}
              placeholder="Искать удаленные документы..."
            />
          </FocusTrap>
          <Box pos={'relative'}>
            <LoadingOverlay
              visible={isRestoreLoading}
              overlayProps={{ blur: 3 }}
            />
            {!data?.pages.length ? (
              <NoData
                mt={48}
                illustration="trash"
                description="Корзина пуста"
              />
            ) : (
              data?.pages.map((p) => (
                <Link
                  to={generateLink(AppRoutes.pages, { pageId: p.id })}
                  className={styles.item}
                  key={p.id}
                >
                  <Flex gap={8}>
                    {p.icon ? (
                      <Box fz={20}>{p.icon}</Box>
                    ) : (
                      <IconFile size={20} />
                    )}
                    <Stack gap={2}>
                      <Text fz={16} lh={1.2}>
                        {p.title || t('noName')}
                      </Text>
                      <Text fz={14} lh={1.2} c="dimmed">
                        Удалена{' '}
                        {formatDistanceToNow(p.deletedAt, {
                          addSuffix: true,
                          includeSeconds: false,
                        })}
                      </Text>
                    </Stack>
                  </Flex>

                  <Flex gap={0}>
                    <Tooltip label="Восстановить" openDelay={100}>
                      <ActionIcon
                        variant="subtle"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleRestore(p.id, p.title);
                        }}
                      >
                        <IconCornerUpLeft size={16} />
                      </ActionIcon>
                    </Tooltip>
                    <Tooltip label="Удалить безвозратно" openDelay={100}>
                      <ActionIcon
                        variant="subtle"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          removePage(p.title, p.id, { permanent: true });
                        }}
                      >
                        <IconX size={16} />
                      </ActionIcon>
                    </Tooltip>
                  </Flex>
                </Link>
              ))
            )}
          </Box>
        </Stack>
      </Container>
    </>
  );
};
