import { useEffect, useState } from 'react';

import FlagRu from '@/assets/images/flags/ru.svg';
import FlagUs from '@/assets/images/flags/us.svg';
import { Group, Image, Menu, UnstyledButton } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import PropTypes from 'prop-types';

import classes from './LanguagePicker.module.css';

const flagMap: { [key: string]: string } = {
  ru: FlagRu,
  en: FlagUs,
};

type Language = {
  value: string;
  label: string;
};

interface LanguagePickerProps {
  data: Language[];
  value: string;
  onChange: (value: string) => void;
}

export const LanguagePicker = ({
  data,
  value,
  onChange,
}: LanguagePickerProps) => {
  const [opened, setOpened] = useState(false);
  const [selected, setSelected] = useState(
    data.find((lang) => lang.value === value) || data[0],
  );

  useEffect(() => {
    setSelected(data.find((lang) => lang.value === value) || data[0]);
  }, [data, value]);

  const items = data.map((item) => (
    <Menu.Item
      leftSection={
        <Image
          src={flagMap[item.value]}
          width={18}
          height={18}
          className={classes.image}
        />
      }
      onClick={() => onChange(item.value)}
      key={item.value}
    >
      {item.label}
    </Menu.Item>
  ));

  return (
    <Menu
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      radius="md"
      width="target"
      withinPortal
    >
      <Menu.Target>
        <UnstyledButton
          className={classes.control}
          data-expanded={opened || undefined}
        >
          <Group gap="xs">
            <Image
              src={flagMap[selected.value]}
              className={classes.image}
              width={18}
              height={18}
            />
            <span className={classes.label}>{selected.label}</span>
          </Group>
          <IconChevronDown size="1rem" className={classes.icon} stroke={1.5} />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>{items}</Menu.Dropdown>
    </Menu>
  );
};

LanguagePicker.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
